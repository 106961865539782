import { AuthService } from "@modules/non-authenticated-modules/authentication/services/auth.service";
import { config, Environments } from "@environments/index";
import { HttpClient } from "@angular/common/http";
import { MockAuthService } from "@modules/non-authenticated-modules/authentication/services/auth.service.mock";

export function AuthServiceFactory(httpClient: HttpClient) {
    switch (config.environment) {
        case Environments.local:
          return new MockAuthService(httpClient);
        default:
          return new AuthService();
      }
}
