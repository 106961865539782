import {ErrorHandler, inject, NgModule, provideAppInitializer} from "@angular/core";
import {AppComponent} from "./app.component";
import {AuthenticatedModulesModule} from "@modules/authenticated-modules/authenticated-modules.module";
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {BrowserModule} from "@angular/platform-browser";
import {ConfigService} from "@core/services/config.service";
import {dbConfig} from "@core/data/idb/config/idb.config";
import {en_US, NZ_I18N} from "ng-zorro-antd/i18n";
import {EnvironmentLoader} from "@core/app-init/environment-loader";
import {config, Environments} from "@environments/index";
import {GlobalErrorHandler} from "@core/providers/global-error-handler";
import {provideHttpClient, withInterceptorsFromDi} from "@angular/common/http";
import {LoadingComponent} from "@core/components/loading/loading.component";
import {NgxIndexedDBModule} from "ngx-indexed-db";
import {NonAuthenticatedModulesModule} from "@modules/non-authenticated-modules/non-authenticated-modules.module";
import {NzModalModule} from "ng-zorro-antd/modal";
import {NzSpinModule} from "ng-zorro-antd/spin";
import {ServiceWorkerModule} from "@angular/service-worker";
import {TenantConfigService} from "@core/services/tenant-config.service";
import * as Sentry from "@sentry/angular";
import {Router} from "@angular/router";
import {AppRoutingModule} from "./app-routing.module";
import {NotAllowedComponent} from "@core/components/not-allowed/not-allowed.component";
import {NzResultModule} from "ng-zorro-antd/result";

@NgModule({ declarations: [
        AppComponent,
        LoadingComponent,
        NotAllowedComponent
    ],
    bootstrap: [AppComponent], imports: [NgxIndexedDBModule.forRoot(dbConfig),
        AppRoutingModule,
        NonAuthenticatedModulesModule,
        AuthenticatedModulesModule,
        BrowserAnimationsModule,
        BrowserModule,
        NzModalModule,
        NzSpinModule,
        NzResultModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: config.environment === Environments.prod || config.environment === Environments.uat ? true : false,
        })], providers: [
        {
            provide: ErrorHandler,
            useClass: GlobalErrorHandler,
        },
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler()
        },
        {
            provide: Sentry.TraceService,
            deps: [Router]
        },
        provideAppInitializer(() => {
        const initializerFn = EnvironmentLoader(inject(ConfigService));
        return initializerFn();
      }),
        {
            provide: NZ_I18N,
            useValue: en_US
        },
        {
            provide: TenantConfigService,
            deps: [ConfigService]
        },
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class AppModule { }
