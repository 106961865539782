import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class DataService<T> {

  private dataSubject: BehaviorSubject<T | null> = new BehaviorSubject<T | null>(null);
  private data$: Observable<T | null> = this.dataSubject.asObservable();

  constructor() {
  }

  setData(data: T): void {
    this.dataSubject.next(data);
  }

  getData(): Observable<T | null> {
    return this.data$;
  }
}
