import { Environments } from 'src/environments';

/*
  Logging for dev only
*/
class AppLogger {
  log = this.getLogger('log');
  error = this.getLogger('error');
  assert = this.getLogger('assert');
  debug = this.getLogger('debug');
  table = this.getLogger('table');

  getLogger<T extends logKey>(theLogger: T): Console[T] {
    return Environments.prod
      ? () => {}
      : Function.prototype.bind.call(console[theLogger], console);
  }
}

type logKey = keyof Console;

export const logger = new AppLogger();
