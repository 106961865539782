//import { db } from '@core/services/db.service';
import {AuthDbStoreService} from "@modules/non-authenticated-modules/authentication/services/auth.db.store";
import {ConfigService} from "@core/services/config.service";
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import {Injectable} from "@angular/core";
import {from, lastValueFrom, Observable} from "rxjs";
import {TokenService} from "@core/services/token.service";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  // list of url's to exclude from headers
  excludeURLList = [
    '/addUserToGroup',
    '/removeUserFromGroup',
    '/createUser',
    'deleteUser',
    '/confirmUserSignUp',
    '/disableUser',
    '/enableUser',
    '/getUser',
    '/listUsers',
    '/listGroups',
    '/listGroupsForUser',
    '/listUsersInGroup',
    '/signUserOut',
    '/reset',
    '/resend'
  ];

  constructor(
    private configService: ConfigService,
    private storeService: AuthDbStoreService,
    private tokenService: TokenService
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // convert promise to observable using 'from' operator
    return from(this.handle(req, next))
  }

  async handle(req: HttpRequest<any>, next: HttpHandler) {
    let excludeFound = this.excludeURLList.filter(uri => {
      return req.url.includes(uri)
    });

    const key = this.configService.apiKey;
    const accessToken = await this.tokenService.getAccessToken();
    const idToken = await this.tokenService.getIdToken();

    if (key && accessToken && idToken && excludeFound.length <= 0) {
      req = req.clone({
        headers: req.headers
          .set('Authorization', `Bearer ${accessToken}`)
          .set('Authentication', `Token ${idToken}`)
          .set('X-Api-Key', key)
      });
    } else if (accessToken && key && idToken && excludeFound.length > 0) {
      req = req.clone({
        headers: req.headers
          .set('Authentication', `${idToken}`)
          .set('X-Api-Key', key)
      });
    } else {
      await this.tokenService.logOut();
    }

    return await lastValueFrom(next.handle(req));
  }

}
