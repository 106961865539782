import {
  AbstractAuthService
} from '@modules/non-authenticated-modules/authentication/abstract-classes/auth-service.abstract';
import {AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthDbStoreService} from '@modules/non-authenticated-modules/authentication/services/auth.db.store';
import {CognitoError} from '@models/auth/cognito-error.model';
import {CognitoUser} from 'amazon-cognito-identity-js';
import {Component, OnInit} from '@angular/core';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';
import {LoadingService} from '@core/services/loading.service';
import {NzNotificationService} from 'ng-zorro-antd/notification';
import {TenantConfigService} from '@core/services/tenant-config.service';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {TokenService} from "@core/services/token.service";
import {MfaOptions} from "@enums/mfa-options.enum";
import {CognitoErrorCodes} from "@enums/cognito-error-codes.enum";
import {DataService} from "@core/services/data.service";
import {CognitoChallenges} from "@enums/cognito-challenges.enum";

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  standalone: false
})
export class LoginComponent implements OnInit {
  tenantAuthLogo: SafeHtml | undefined;
  signInForm!: UntypedFormGroup;
  otpRequested: boolean = false;
  restPasswordOtp: boolean | undefined;
  signupOtp: boolean | undefined;
  enabledOtpResend: boolean = false;
  forgotPasswordReq: boolean = false;
  user!: CognitoUser | any;
  authTimeout: any;
  otpInterval: any;
  otpRequestTimer: number = 60;
  signingIn: boolean = false;
  passwordVisible: boolean = false;

  loadingService: LoadingService;

  userName!: string;
  passwd!: string;

  newUsername!: string;

  constructor(
    globalLoadingService: LoadingService,
    private activatedRoute: ActivatedRoute,
    private authService: AbstractAuthService,
    private authStore: AuthDbStoreService,
    private fb: UntypedFormBuilder,
    private notificationService: NzNotificationService,
    private router: Router,
    private sanitizer: DomSanitizer,
    private tenantService: TenantConfigService,
    private tokenService: TokenService,
    private tempUserService: DataService<CognitoUser>
  ) {
    this.loadingService = globalLoadingService;
    this.activatedRoute.queryParams
      .pipe(untilDestroyed(this))
      .subscribe(params => {
        this.restPasswordOtp = params['reset'] || undefined;
        this.signupOtp = params['signup'] || undefined;
        this.newUsername = params['newUsername'] || undefined;
        this.userName = params['username'] || undefined;
        this.passwd = params['password'] || undefined;
      });
  }

  async ngOnInit(): Promise<void> {
    this.initForm(null, null);

    await this.setTenantLogo();

    if (this.restPasswordOtp) {
      await this.retrieveUser()
        .then((): void => {
          this.authenticationTimeout();
        });
    }

    if (this.signupOtp) {
      this.authenticationTimeout();
    }

    if (this.userName && this.passwd !== undefined) {
      this.initForm(decodeURI(this.userName), decodeURI(this.passwd));
      await this.authenticate();
    }
  }

  private async setTenantLogo() {
    this.tenantAuthLogo = this.sanitizer.bypassSecurityTrustHtml(await this.tenantService.getAuthLogo());
  }

  private initForm(userName?: string | null, pwd?: string | null): void {
    this.signInForm = this.fb.group({
      userName: [userName, [Validators.required]],
      password: [pwd, [Validators.required]],
      remember: [false]
    });
  }

  async authenticate(): Promise<void> {
    this.signingIn = true;
    await this.authService.signIn(this.username?.value.trim(), this.pwd?.value.trim()).then(
      async (user: CognitoUser | any): Promise<void> => {
        switch (user.challengeName) {
          case CognitoChallenges.NEW_PASSWORD_REQUIRED:
            this.tempUserService.setData(user);
            await this.router.navigate(['auth', 'reset'],
              {
                state: {
                  challenge: user.challengeName,
                  requestFrom: 'login'
                }
              });
            this.signingIn = false;
            break;
          case MfaOptions.SMS_MFA:
            this.user = user;
            this.authenticationTimeout();
            this.signingIn = false;
            break;
        }

        if (user.preferredMFA === MfaOptions.NOMFA) {
          this.authStore.addUser(user, this.pwd?.value)
            .then(async () => {
              await this.tokenService.initTokens(user);
              if (this.remember?.value) {
                await this.authService.rememberDevice()
                  .then()
                  .catch();
              }
              this.signingIn = false;
              await this.router.navigateByUrl('dashboard');
            });
        }
      }).catch(async (e: CognitoError) => {
      if (e.code === CognitoErrorCodes.PasswordResetRequiredException) {
        await this.router.navigate(['auth', 'reset'],
          {
            queryParams: {
              username: this.username?.value,
              mfa: this.pwd?.value
            },
            state: {
              challenge: undefined,
              name: 'null',
              username: 'null',
              requestFrom: 'login'
            }
          });
      }
      this.notificationService.error('Sign in error', e.message);
      this.signingIn = false;
    });
  }

  async forgotPassword(resend?: boolean | undefined | null): Promise<void> {
    await this.authService.forgotPassword(this.username?.value)
      .then(async (value: any): Promise<void> => {
        this.notificationService.info('Sign in info', `OTP sent to ${value.CodeDeliveryDetails.Destination}`);

        if (resend) {
          this.enabledOtpResend = false;
          clearInterval(this.otpInterval);
          this.otpTimer();
        } else {
          this.authenticationTimeout();
        }
      })
      .catch((e) => {
        /*if(e.__type === 'LimitExceededException'){
          this.notificationService.error('Auth error', e.message);
        }else {*/ //TODO:FIND COGNITO ERROR OBJECT
        this.notificationService.error('Auth error', e);
      })
  }

  async resendOtp(): Promise<void> {
    if (this.enabledOtpResend && !this.forgotPasswordReq && !this.restPasswordOtp) {
      await this.authService.signIn(this.username?.value, this.pwd?.value)
        .then((v) => {
          this.user = v;
          this.notificationService.info('Sign in info', `OTP sent to ${this.user.challengeParam.CODE_DELIVERY_DESTINATION}`);
          this.enabledOtpResend = false;
          this.otpRequestTimer = 60;
        })
        .catch((e) => this.notificationService.error('Sign in error', e.message));
    } else if (this.enabledOtpResend && this.forgotPasswordReq) {
      this.forgotPassword(true);
    } else if (this.enabledOtpResend && this.signupOtp) {
      this.authService.resendSignUp(this.newUsername)
        .then((v) => console.log(v))
        .catch((err) => console.error(err))
    } else if (this.enabledOtpResend && this.restPasswordOtp) {
      this.user.resendConfirmationCode((): void => {
        this.enabledOtpResend = false;
        this.otpRequestTimer = 60;
      });
    }
  }

  toggleForgotPassword(): void {
    this.forgotPasswordReq = !this.forgotPasswordReq;

    if (this.forgotPasswordReq) {
      this.pwd?.disable();
    } else {
      this.pwd?.enable();
    }
  }

  async onOtpChange(otp: string): Promise<void> {
    if (otp.length === 6) {
      if (this.forgotPasswordReq) {
        clearInterval(this.otpInterval);
        clearTimeout(this.authTimeout);
        await this.router.navigate(['auth', 'reset'], {
          queryParams: {username: this.username?.value, mfa: otp},
          state: {
            challenge: undefined,
            name: 'null',
            username: 'null',
            requestFrom: 'login'
          }
        });
      } else if (this.signupOtp) {
        this.loadingService.showLoading();
        this.authService.confirmSignUp(this.newUsername, otp)
          .then((): void => {
            clearInterval(this.otpInterval);
            clearTimeout(this.authTimeout);
            this.notificationService.info('Sign up info', 'User successfully created');
            this.signupOtp = false;
            this.otpRequested = false;
            this.loadingService.hideLoading();
          })
          .catch((err) => {
            this.loadingService.hideLoading();
            console.error(err);
          })
      } else {
        this.loadingService.showLoading();
        await this.authService.confirmSignIn(this.user, otp)
          .then((authUser) => {
            clearInterval(this.otpInterval);
            clearTimeout(this.authTimeout);
            this.authStore.addUser(authUser, this.pwd?.value)
              .then(async () => {
                await this.tokenService.initTokens(authUser);
                if (this.remember?.value) {
                  await this.authService.rememberDevice()
                    .then()
                    .catch();
                }
                await this.router.navigateByUrl('dashboard');
              });
          })
          .catch((e) => {
            this.loadingService.hideLoading();
            this.notificationService.error('Sign in error', e.message);
          });
      }
    }
  }

  private async retrieveUser() {

    this.authStore.getCognitoUser()
      .pipe(untilDestroyed(this))
      .subscribe((user: CognitoUser): void => {
        this.user = user;
      });
  }

  private authenticationTimeout(): void {
    this.otpTimer();
    this.authTimeout = setTimeout(() => {
      this.notificationService.error('Sign in error', 'Authentication timeout');
      this.otpRequested = false;
      this.otpRequestTimer = 60;
      clearInterval(this.otpInterval);
      clearTimeout(this.authTimeout);
    }, 180000);
  }

  private otpTimer(): void {
    this.otpRequested = true;
    this.otpRequestTimer = 60;
    this.otpInterval = setInterval(() => {
      if (this.otpRequestTimer > 0) {
        this.otpRequestTimer--;
      } else {
        this.enabledOtpResend = true;
      }
    }, 1000);
  }

  get username(): AbstractControl<any, any> | null {
    return this.signInForm.get('userName');
  }

  get pwd(): AbstractControl<any, any> | null {
    return this.signInForm.get('password');
  }

  get remember(): AbstractControl<any, any> | null {
    return this.signInForm.get('remember');
  }
}
