import {
  AbstractAuthService
} from "@modules/non-authenticated-modules/authentication/abstract-classes/auth-service.abstract";
import {AuthDbStoreService} from "@modules/non-authenticated-modules/authentication/services/auth.db.store";
import {CognitoUser} from "amazon-cognito-identity-js";
import {Injectable} from "@angular/core";
import {LoadingService} from "./loading.service";
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  private accessToken: string;
  private idToken: string;
  private tokenExpiration: number;


  constructor(
    private loadingService: LoadingService,
    private authService: AbstractAuthService,
    private dbStoreService: AuthDbStoreService,
    private router: Router,
  ) {
    dbStoreService.getUser()
      .then(user => {
        this.accessToken = user.accessToken!;
        this.idToken = user.idToken!;
        this.tokenExpiration = user.tokenExpiration!;
      })
      .catch(err => {
        console.error('Error initializing tokens: ', err);
      });
  }

  async initTokens(user: CognitoUser): Promise<void> {
    try {
      const session = user.getSignInUserSession();
      this.accessToken = session!.getAccessToken().getJwtToken();
      this.idToken = session!.getIdToken().getJwtToken();
      this.tokenExpiration = session!.getAccessToken().getExpiration();
    } catch (err) {
      console.error('Error initializing tokens: ', err);
      await this.logOut();
    }
  }

  async isAccessTokenExpired(): Promise<boolean> {
    if (!this.tokenExpiration) {
      return true;
    }
    return Math.floor(Date.now()/1000) >= this.tokenExpiration;
  }

  async getAccessToken(): Promise<string | null> {
    return await this.isAccessTokenExpired()
      .then(async res => {
        if (res) {
          try {
            await this.refreshAccessToken();
          } catch (err) {
            console.error("Failed to refresh access token: ", err);
            await this.logOut();
          }
        }
        return this.accessToken;
      });
  }

  async getIdToken(): Promise<string | null> {
    return await this.isAccessTokenExpired()
      .then(async res => {
        if (res) {
          try {
            await this.refreshAccessToken();
          } catch (err) {
            console.error("Failed to refresh access token: ", err);
            await this.logOut();
          }
        }
        return this.idToken;
      });
  }


  private async refreshAccessToken(): Promise<void> {
    try {
      const currentUser = await this.authService.currentAuthenticatedUser() as CognitoUser;
      const currentSession = await this.authService.currentSession();
      if (currentSession.isValid()) {
        await this.dbStoreService.updateUser(currentUser);
        this.accessToken = currentSession.getAccessToken().getJwtToken();
        this.idToken = currentSession.getIdToken().getJwtToken();
      } else {
        console.error("Invalid session. PLease Login again");
        await this.logOut();
      }
    } catch (err) {
      console.error("Error occurred when trying to refresh token: ", err);
      await this.logOut();
    }
  }

  async logOut(): Promise<void> {
    this.loadingService.showLoading();
    await this.authService.signOut()
      .then(async () => {
        await this.dbStoreService.clearStorage()
          .then(() => {
            this.router.navigateByUrl('auth/login');
            this.loadingService.hideLoading();
          });
      });
  }

}
