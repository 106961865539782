import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { LoadingService } from '@core/services/loading.service';
import { UniversalDeviceDetectorService } from '@core/services/universal-device-detector.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Subscription, debounceTime } from 'rxjs';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  isLoading = true;
  loadingSubscription: Subscription;
  globalLoadingService: LoadingService;

  constructor(
    private ds: UniversalDeviceDetectorService,
    private router: Router,
    private updates: SwUpdate,
    private modalService: NzModalService,
    private messageService: NzMessageService,
    loadingService: LoadingService,
    ) {
      this.globalLoadingService = loadingService;
    }

    async ngOnInit(): Promise<void> {
      this.swApplicationUpdates();
      this.deviceInit();
      this.applicationLoadingListener();
      await this.appInitLoad().then((result: boolean) => this.isLoading = false);
    }

    private swApplicationUpdates(){
      if(this.updates.isEnabled){
        this.updates.checkForUpdate()
        .then((res) => {
          if(res){
            this.modalService.confirm({
              nzTitle: 'Software Update',
              nzContent: 'A new version of AuthentIq is available. Do you wish to update now or later?',
              nzOkText: 'Update Now!',
              nzCancelText: 'Later',
              nzIconType: 'build',
              nzOnOk: () => 
                new Promise((res, rej) => {
                  window.location.href = '/';
                })
            });
          }
        })
      
      this.updates.versionUpdates.subscribe(event => {
        switch(event.type){
          case 'NO_NEW_VERSION_DETECTED':
            console.log('No new version.');
            break;
          case 'VERSION_DETECTED':
            this.messageService.info('New version detected. Downloading...');
            break;
          case 'VERSION_INSTALLATION_FAILED':
            this.messageService.error('Latest version installation failed. Please reload application.');
            break;
          case 'VERSION_READY':
            this.messageService.success('New version ready to install');
            break;
        }
      });
      
    }
    }

    private deviceInit(){
      //console.log(this.ds.getDeviceInfo());
    }

    private async appInitLoad(): Promise<boolean>{
      return await this.router.navigateByUrl('/auth/login').then((res: boolean) => 
        new Promise(resolve => 
          setTimeout(() => resolve(res), 1000)  
        ));
    }

    private applicationLoadingListener(){
      this.loadingSubscription = 
      this.globalLoadingService.loading$
      .pipe(debounceTime(200))
      .subscribe((status) => this.isLoading = status);
    }
}
