// import { LayoutComponent } from "@core/components/layout/layout.component";
// import { LoadingComponent } from "@core/components/loading/loading.component";
// import { MenuItemComponent } from "@core/components/layout/menu-item/menu-item.component";
// import { NotAuthComponent } from "@core/components/not-auth/not-auth.component";
import { AmplifyAuthenticatorModule } from "@aws-amplify/ui-angular";
import { AuthDbStoreService } from "./authentication/services/auth.db.store";
import { AuthenticationModule } from "./authentication/authentication.module";
import { ConfigService } from "@core/services/config.service";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { LoadingInterceptor } from "@core/interceptors/loading.interceptor";
import { LoadingService } from "@core/services/loading.service";
import { NgModule } from "@angular/core";
import { NgxIndexedDBModule } from "ngx-indexed-db";
import { NonAuthenticatedModulesRoutingModule } from "./non-authenticated-modules-routing.module";
import { TokenInterceptor } from "@core/interceptors/token.interceptor";
import { TokenService } from "@core/services/token.service";

@NgModule({
  imports: [
    AmplifyAuthenticatorModule,
    AuthenticationModule,
    NonAuthenticatedModulesRoutingModule,
    NgxIndexedDBModule
  ],
  declarations: [
    //NotAuthComponent
  ],
  providers: [
    LoadingService,
    {
        provide: HTTP_INTERCEPTORS,
        useClass: LoadingInterceptor,
        multi: true
      },
      { 
        provide: HTTP_INTERCEPTORS,
        useClass: TokenInterceptor,
        deps: [ ConfigService, AuthDbStoreService, TokenService ],
        multi: true
      },
  ],

})
export class NonAuthenticatedModulesModule { }
