import { Component } from '@angular/core';

@Component({
    selector: 'app-auth-routing',
    templateUrl: './routing.component.html',
    standalone: false
})
export class AuthRoutingComponent {

  constructor() { }
  
}
