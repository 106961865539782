//import { RegistrationComponent } from './components/registration/registration.component';
import { AbstractAuthService } from '@modules/non-authenticated-modules/authentication/abstract-classes/auth-service.abstract';
import { AuthDbStoreService } from '@modules/non-authenticated-modules/authentication/services/auth.db.store';
import { AuthenticationRoutingModule } from './authentication-routing.module';
import { AuthRoutingComponent } from './routing/routing.component';
import { AuthServiceFactory } from '@modules/non-authenticated-modules/authentication/factories/auth-service.factory';
import { ConfigService } from '@core/services/config.service';
import { HttpClient } from '@angular/common/http';
import { LoginComponent } from './components/login/login.component';
import { NgModule } from '@angular/core';
import { NgOtpInputModule } from 'ng-otp-input';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { NgxMaskDirective, NgxMaskPipe } from 'ngx-mask';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { SharedModule } from '@shared/shared.module';


@NgModule({
  imports: [
    SharedModule,
    AuthenticationRoutingModule,
    NgOtpInputModule,
    NgxMaskDirective, NgxMaskPipe
  ],
  declarations: [
    LoginComponent, 
    AuthRoutingComponent,
    //RegistrationComponent, 
    ResetPasswordComponent
  ],
  providers: [
    { provide: AbstractAuthService, useFactory: AuthServiceFactory, deps: [HttpClient] },
    { provide: AuthDbStoreService, deps: [ConfigService, NgxIndexedDBService] },
  ],
  exports: [
    AuthRoutingComponent
  ]
})
export class AuthenticationModule { }
