import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotAllowedComponent } from '@core/components/not-allowed/not-allowed.component';
import { AppRoutes } from '@enums/app-routes.enum';

const routes: Routes = [
  {
    path:'',
    redirectTo: 'auth',
    pathMatch: 'full'
  },
  {
    path: AppRoutes.Not_Supported,
    component: NotAllowedComponent
  }
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
