<div class="auth-wrapper">
    <form class="login-form" nz-form [formGroup]="resetPasswordForm">
        <div class="image-wrapper">
          <div [innerHTML]="tenantAuthLogo"></div>
        </div>

        <div>
            <nz-form-item *ngIf="isPasswordChange">
              <nz-input-group [nzSuffix]="oldSuffixTemplate">
                  <input
                    id="oldPassword"
                    [type]="oldPasswordVisible ? 'text' : 'password'"
                    nz-input
                    placeholder="Old Password"
                    formControlName="oldPassword"
                  />
                </nz-input-group>
            </nz-form-item>
  
            <nz-form-item>
              <nz-input-group [nzSuffix]="newSuffixTemplate">
                  <input
                    id="newPassword"
                    [type]="newPasswordVisible ? 'text' : 'password'"
                    nz-input
                    placeholder="New Password"
                    formControlName="newPassword"
                  />
                </nz-input-group>
  
                <div class="password-error">
                  <nz-alert nzType="info" nzMessage="Password needs to be a minimum of 8 characters" *ngIf="newPassword?.errors?.['minlength']"></nz-alert>
                  <nz-alert nzType="error" nzMessage="Password can only be a maximum of 99 characters" *ngIf="newPassword?.errors?.['maxlength']"></nz-alert>
                  <nz-alert nzType="info" nzMessage="Password needs to have at least one lowercase letter [a-z]" *ngIf="newPassword?.errors?.['requireLowerCase']"></nz-alert>
                  <nz-alert nzType="info" nzMessage="Password needs to have at least one uppercase letter [A-Z]" *ngIf="newPassword?.errors?.['requireUpperCase']"></nz-alert>
                  <nz-alert nzType="info" nzMessage="Password needs to have at least one number [0-9]" *ngIf="newPassword?.errors?.['requireNumbers']"></nz-alert>
                  <nz-alert nzType="info" nzMessage="Password needs to have at least one symbol character" *ngIf="newPassword?.errors?.['requireSymbols']"></nz-alert>
                </div>
            </nz-form-item>
  
            <nz-form-item>
              <nz-input-group [nzSuffix]="verifySuffixTemplate">
                  <input
                  id="verifyPassword"
                    [type]="verifyPasswordVisible ? 'text' : 'password'"
                    nz-input
                    placeholder="Confirm Password"
                    formControlName="verifyPassword"
                  />
                </nz-input-group>
  
                <div class="password-error">
                  <nz-alert nzType="error" nzMessage="Passwords do not match" *ngIf="verifyPassword?.errors?.['mustMatch']"></nz-alert>
                </div>
            </nz-form-item>
  
            <ng-template #oldSuffixTemplate>
              <span
                nz-icon
                [nzType]="oldPasswordVisible ? 'eye-invisible' : 'eye'"
                (click)="oldPasswordVisible = !oldPasswordVisible"
              ></span>
            </ng-template>
  
            <ng-template #newSuffixTemplate>
              <span
                nz-icon
                [nzType]="newPasswordVisible ? 'eye-invisible' : 'eye'"
                (click)="newPasswordVisible = !newPasswordVisible"
              ></span>
            </ng-template>
  
            <ng-template #verifySuffixTemplate>
              <span
                nz-icon
                [nzType]="verifyPasswordVisible ? 'eye-invisible' : 'eye'"
                (click)="verifyPasswordVisible = !verifyPasswordVisible"
              ></span>
            </ng-template>
  
            <button nz-button class="login-form-button login-form-margin" nzType="primary" id="submit" (click)="resetPassword()" [disabled]="!resetPasswordForm.valid" [nzLoading]="submit">{{btnSubmitText}}</button>
  
        </div>
    </form>
</div>
