<div class="auth-wrapper">
  <form class="login-form" nz-form [formGroup]="signInForm">
    <div class="image-wrapper">
      <div [innerHTML]="tenantAuthLogo"></div>
    </div>

    <div *ngIf="!otpRequested">
      <nz-form-item>
        <nz-form-control nzErrorTip="Please input your Username!">
          <nz-input-group [nzPrefix]="prefixTemplateUser">
            <input
              type="text"
              nz-input
              formControlName="userName"
              placeholder="Username"
              id="username"
            />
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item *ngIf="!forgotPasswordReq">
        <nz-form-control nzErrorTip="Please input your Password!">
          <nz-input-group [nzPrefix]="prefixTemplateLock" [nzSuffix]="hideShowTemplate">
            <input
            [type]="passwordVisible ? 'text' : 'password'"
              nz-input
              formControlName="password"
              placeholder="Password"
              id="password"
            />
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>
      <ng-template #prefixTemplateUser
        ><span nz-icon nzType="user"></span
      ></ng-template>
      <ng-template #prefixTemplateLock
        ><span nz-icon nzType="lock"></span
      ></ng-template>
      <ng-template #hideShowTemplate>
        <span
          nz-icon
          [nzType]="passwordVisible ? 'eye-invisible' : 'eye'"
          (click)="passwordVisible = !passwordVisible"
        ></span>
      </ng-template>
      <div nz-row class="login-form-margin">
        <div nz-col nzSpan="12">
          <label
            nz-checkbox
            formControlName="remember"
            *ngIf="!forgotPasswordReq"
            id="remeberChkBox"
          >
            Remember me
          </label>
        </div>
        <div nz-col nzSpan="12" *ngIf="!forgotPasswordReq">
          <a
            class="login-form-forgot"
            (click)="toggleForgotPassword()"
            id="resetPasswordLink"
            >Forgot password</a
          >
        </div>
        <div nz-col nzSpan="12" *ngIf="forgotPasswordReq">
          <a
            class="login-form-forgot"
            (click)="toggleForgotPassword()"
            id="loginLink"
            >Log In</a
          >
        </div>
      </div>
      <div *ngIf="forgotPasswordReq">
        <button
          nz-button
          class="login-form-button login-form-margin"
          nzType="primary"
          (click)="forgotPassword()"
          [disabled]="!signInForm.valid"
          id="resetPasswordbtn"
        >
          Forgot Password
        </button>
      </div>
      <div *ngIf="!forgotPasswordReq">
        <button
          nz-button
          class="login-form-button login-form-margin"
          nzType="primary"
          (click)="authenticate()"
          [disabled]="!signInForm.valid"
          id="loginBtn"
          [nzLoading]="signingIn"
        >
          Log In
        </button>
        <!-- Or
        <a routerLink="/auth/registration" id="registerLink">register now!</a> -->
      </div>
    </div>

    <div *ngIf="otpRequested" class="otp-input">
      <h3 class="mb-2 mb-3">Verification code</h3>
      <p class="text-center">
        We have sent a verification code to your registered phone number / email.
      </p>
      <p class="text-center">Please enter the code below.</p>
      <ng-otp-input
        (onInputChange)="onOtpChange($event)"
        [config]="{ length: 6 }"
      ></ng-otp-input>
      <a
        id="otpResendLink"
        [ngClass]="{
          disabledOtpResend: !enabledOtpResend,
          enabledOtpResend: enabledOtpResend
        }"
        (click)="resendOtp()"
        >resend
        <span *ngIf="!enabledOtpResend">({{ otpRequestTimer }})</span></a
      >
    </div>
  </form>
</div>
