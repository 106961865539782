import { ConfigService } from "./config.service";
import { firstValueFrom } from "rxjs";
import { Injectable } from "@angular/core";

@Injectable()
export class TenantConfigService {

    constructor(private service: ConfigService){}

    async getApplicationLogo(){
        return await firstValueFrom(this.service.getSVG(this.service.applicationLogo));
      }

    async getAuthLogo(){
        return await firstValueFrom(this.service.getSVG(this.service.authenticationLogo));
      }

}
