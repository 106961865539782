import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { TenantConfigService } from '@core/services/tenant-config.service';

@Component({
    selector: 'app-not-allowed',
    templateUrl: './not-allowed.component.html',
    styleUrls: ['./not-allowed.component.scss'],
    standalone: false
})
export class NotAllowedComponent {

  tenantAuthLogo: SafeHtml | undefined;

  constructor(private sanitizer: DomSanitizer,
    private tenantService: TenantConfigService,
  ) {
    this.setTenantLogo();
   }

  private async setTenantLogo(){
    this.tenantAuthLogo = this.sanitizer.bypassSecurityTrustHtml(await this.tenantService.getAuthLogo());
  }
}
