import { AbstractAuthService } from '@modules/non-authenticated-modules/authentication/abstract-classes/auth-service.abstract';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthDbStoreService } from '@modules/non-authenticated-modules/authentication/services/auth.db.store';
import { BuildMenuService } from '@core/services/build-menu.service';
import { Component, OnInit } from '@angular/core';
import { ConfigService } from '@core/services/config.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { LayoutPageDataService } from '@core/services/layout.service';
import { LoadingService } from '@core/services/loading.service';
import { SystemRoles } from '@enums/system-roles.enum';
import { TenantConfigService } from '@core/services/tenant-config.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { UserMenuModel } from '@models/user-menu/user-menu.model';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {
  loadingService: LoadingService;
  //layoutService: LayoutPageDataService;
  menuItems: UserMenuModel[] = [];
  name = '';
  email = '';
  pageDescription = '';
  pageTitle = '';
  role: string;
  userInitials = '';
  buildNumber = '';
  tenantLogo: SafeHtml | undefined;

  constructor(
    globalLayoutService: LayoutPageDataService,
    globalLoadingService: LoadingService,
    private authService: AbstractAuthService,
    private activatedRoute: ActivatedRoute, 
    private router: Router, 
    private sanitizer: DomSanitizer,
    private storeService: AuthDbStoreService,
    private userMenuService: BuildMenuService,
    private tenantService: TenantConfigService,
    private service: ConfigService
    ) { 
      this.loadingService = globalLoadingService;
      //this.layoutService = globalLayoutService;
      globalLayoutService.pageTitle$.subscribe((data) => this.handlePageState(data, 'title'));
      globalLayoutService.pageDescription$.subscribe((data) => this.handlePageState(data, 'description'));
    }

  async ngOnInit() {
    await this.setUserDetails();
    await this.setApplicationLogoAndVersion();
  }

  private async setApplicationLogoAndVersion(){
    this.tenantLogo = this.sanitizer.bypassSecurityTrustHtml(await this.tenantService.getApplicationLogo());
    this.buildNumber = this.service.buildNumber;
  }

  private handlePageState(data: any, type: string){
    switch(type){
      case('title'): 
      this.pageTitle = data;
        break;
      case('description'):
      this.pageDescription = data;
        break;
    }
  }

  private async setUserDetails(){
    await this.storeService.getUser().then((usr) => {
     this.userInitials = usr?.userAttributes?.initials!;
     this.name = usr?.userAttributes?.given_name!;
     this.email = usr?.userAttributes?.email!;

     switch(usr?.access[0]){
      case SystemRoles.SuperAdmin: 
        this.role = 'System Owner';
        this.menuItems = this.userMenuService.buildSuperAdminMenu();
        break;
      case SystemRoles.Admin:
        this.role = 'Admin';
        this.menuItems = this.userMenuService.buildAdminMenu();
        break;
      case SystemRoles.Supervisor:
        this.role = 'Manager';
        this.menuItems = this.userMenuService.buildSupervisorMenu();
        break;
      case SystemRoles.Operator:
        this.role = 'Operator';
        this.menuItems = this.userMenuService.buildOperatorMenu();
        break;
      case SystemRoles.General:
        this.role = 'General';
        this.menuItems = this.userMenuService.buildGeneralMenu();
        //FOR DEV
        //this.menuItems = this.userMenuService.buildSuperAdminMenu();
        break;
      default:
        this.role = 'No Role';
        this.menuItems = this.userMenuService.buildGeneralMenu();
     }
    })
  }

  async logOut(){
    this.loadingService.showLoading();
    await this.authService.signOut()
    .then(async () => {
      await this.storeService.clearStorage()
      .then(() => {
        this.router.navigateByUrl('auth/login');
        this.loadingService.hideLoading();
      });
    });
  }
}
