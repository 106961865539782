import {
  AbstractAuthService
} from '@modules/non-authenticated-modules/authentication/abstract-classes/auth-service.abstract';
import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AbstractControlOptions, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {PasswordValidationService} from '@shared/services/password-validation.service';
import {CognitoUser, ISignUpResult} from 'amazon-cognito-identity-js';
import {AuthDbStoreService} from '@modules/non-authenticated-modules/authentication/services/auth.db.store';
import {NzNotificationService} from 'ng-zorro-antd/notification';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {CognitoUserAttributes} from '@models/auth/cognito-user-attr.model';
import {TenantConfigService} from '@core/services/tenant-config.service';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';
import {MfaOptions} from "@enums/mfa-options.enum";
import {tap} from "rxjs";
import {DataService} from "@core/services/data.service";
import {CognitoChallenges} from "@enums/cognito-challenges.enum";

const data: CognitoUserAttributes = {
  gender: 'null',
  given_name: 'null',
  picture: 'null'
};

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
  standalone: false
})
export class ResetPasswordComponent implements OnInit {
  resetPasswordForm!: UntypedFormGroup;
  user!: CognitoUser;
  tenantAuthLogo: SafeHtml | undefined;

  isPasswordChange: boolean = false;
  oldPasswordVisible: boolean = false;
  newPasswordVisible: boolean = false;
  verifyPasswordVisible: boolean = false;
  newUser: boolean = false;
  challenge: string | undefined;
  username: string | undefined;
  newUsername: string | undefined;
  otp: string | undefined;
  btnSubmitText: string = 'Reset Password';
  submit: boolean = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AbstractAuthService,
    private authStore: AuthDbStoreService,
    private fb: UntypedFormBuilder,
    private notificationService: NzNotificationService,
    private router: Router,
    private sanitizer: DomSanitizer,
    private tenantService: TenantConfigService,
    private tempUserService: DataService<CognitoUser>
  ) {
    this.tempUserService.getData()
      .pipe(tap((user: CognitoUser | null) => this.user = user!))
      .subscribe()

    this.activatedRoute.queryParams
      .pipe(untilDestroyed(this))
      .subscribe(params => {
        this.username = params['username'] || undefined;
        this.otp = params['mfa'] || undefined;
        this.newUser = params['user'] || undefined;
      });

    this.challenge = this.router.getCurrentNavigation()?.extras!.state!['challenge'];
    this.newUsername = this.router.getCurrentNavigation()?.extras!.state!['username'];
    data.given_name = this.router.getCurrentNavigation()?.extras!.state!['name'];
    data.phone_number = this.router.getCurrentNavigation()?.extras!.state!['number'];
  }

  async ngOnInit() {
    this.initForm();

    await this.setTenantLogo();

    if (this.challenge === CognitoChallenges.NEW_PASSWORD_REQUIRED) {
      this.oldPassword?.disable();
    }

    if (this.username && this.otp) {
      this.oldPassword?.disable();
    }

    if (this.newUser) {
      this.btnSubmitText = 'Set Password';
      this.oldPassword?.disable();
    }

    this.isPasswordChange = !(this.oldPassword?.disabled!);
  }

  private async setTenantLogo() {
    this.tenantAuthLogo = this.sanitizer.bypassSecurityTrustHtml(await this.tenantService.getAuthLogo());
  }

  private initForm(): void {
    this.resetPasswordForm = this.fb.group({
        oldPassword: ['', [Validators.required]],
        newPassword: ['', [
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(99),
          PasswordValidationService.requireLowerCase,
          PasswordValidationService.requireNumbers,
          PasswordValidationService.requireSymbols,
          PasswordValidationService.requireUpperCase
        ]],
        verifyPassword: ['', [Validators.required]]
      },
      {validators: PasswordValidationService.MustMatch('newPassword', 'verifyPassword')} as AbstractControlOptions
    );
  }

  resetPassword() {
    this.submit = true;
    if (this.challenge === CognitoChallenges.NEW_PASSWORD_REQUIRED) {
      this.authService.completeNewPassword(this.user, this.verifyPassword?.value, data)
        .then(async (u) => {
          this.submit = false;
          if (u.challengeName === MfaOptions.SMS_MFA) {
            this.authStore.setCognitoUser(u);
            await this.router.navigate(['auth', 'login'], {queryParams: {reset: true}});
          }
          if (u.challengeName === undefined) {
            await this.authStore.addUser(u, this.verifyPassword?.value).then(() => this.router.navigateByUrl('dashboard'));
          }
        })
        .catch((e) => {
          this.submit = false;
          this.notificationService.error('Auth error', e.message)
        })
    }

    if (this.username && this.otp) {
      this.authService.forgotPasswordSubmit(this.username, this.otp, this.verifyPassword?.value)
        .then(async (v): Promise<void> => {
          this.submit = false;
          this.notificationService.info('Sign in info', v);
          await this.router.navigateByUrl('auth/login');
        })
        .catch(async (e): Promise<void> => {
          this.notificationService.error('Auth error', e.message);
          this.submit = false;
          await this.router.navigate(['auth', 'login']);
        });
    }

    if (this.newUser) {
      this.authService.signUp({
        username: this.newUsername!,
        password: this.verifyPassword?.value,
        attributes: data
      })
        .then(async (result: ISignUpResult): Promise<void> => {
          this.notificationService.info('Sign up info', `Verification code sent to ${result.codeDeliveryDetails.Destination}`);
          this.submit = false;
          await this.router.navigate(['auth', 'login'], {queryParams: {signup: true, newUsername: this.newUsername}});
        })
        .catch(async (e): Promise<void> => {
          console.log(e);
          await this.router.navigate(['auth', 'login']);
          this.submit = false;
        });
    }
  }

  get oldPassword() {
    return this.resetPasswordForm.get('oldPassword');
  }

  get newPassword() {
    return this.resetPasswordForm.get('newPassword');
  }

  get verifyPassword() {
    return this.resetPasswordForm.get('verifyPassword');
  }
}
