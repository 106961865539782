import { AppRoutes } from '@enums/app-routes.enum';
import { AuthenticatedRoutingComponent } from './module-routing/routing.component';
import { AuthGuard } from '@modules/non-authenticated-modules/authentication/services/auth.guard';
import { LayoutComponent } from '@core/components/layout/layout.component';
import { NgModule } from '@angular/core';
import { NotAuthComponent } from '@core/components/not-auth/not-auth.component';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
    {
        path: '',
        component: AuthenticatedRoutingComponent,
        canMatch: [AuthGuard],
        children: [
          {
            path: '',
            component: LayoutComponent,
            children: [
              {
                path: AppRoutes.Dashboard,
                canActivate: [AuthGuard],
                loadChildren: () => 
                  import('@modules/authenticated-modules/dashboard/dashboard.module').then(
                    m=> m.DashboardModule
                  ),
                data: {
                  title:'Dashboard',
                  description: 'An overview of the statuses and people count',
                  label: 'Dashboard'
                },
              },
              {
                path: AppRoutes.Subjects,
                canActivate: [AuthGuard],
                loadChildren: () => 
                  import('@modules/authenticated-modules/subject-management/subject-management.module').then(
                    m => m.SubjectManagementModule
                  ),
                data: {
                  title:'Manage People',
                  description: 'Add, Edit, Remove or View person details',
                  label: 'People Management'
                },
              
              },
              {
                  path: AppRoutes.Questions,
                  canActivate: [AuthGuard],
                  loadChildren: () => 
                    import('@modules/authenticated-modules/question-management/question-management.module').then(
                      m => m.QuestionManagementModule
                    ),
                  data: {
                    title:'Manage Questions',
                    description: 'Questions are managed from here. The same questions can be used in different question sets. You may create, view, edit or remove questions',
                    label: 'Question Management'
                  }
              },
              {
                path: AppRoutes.Question_Sets,
                canActivate: [AuthGuard],
                loadChildren: () => 
                  import('@modules/authenticated-modules/question-set-management/question-set-management.module').then(
                    m => m.QuestionSetManagementModule
                  ),
                data: {
                  title:'Manage Questions Sets',
                  description: 'Question sets are managed from here. You may create, view, edit or remove question sets',
                  label: 'Question Set Management'
                }
              },
              {
                  path: AppRoutes.Audio,
                  canActivate: [AuthGuard],
                  loadChildren: () => 
                    import('@modules/authenticated-modules/audio-management/audio-management.module').then(
                      m=> m.AudioManagementModule
                    ),
                  data: {
                    title:'Manage Audio',
                    description: 'Assign audio to person, run analysis or remove audio from system',
                    label: 'Audio Management'
                  }
              },
              {
                  path: AppRoutes.Users,
                  canActivate: [AuthGuard],
                  loadChildren: () => 
                    import('@modules/authenticated-modules/user-management/user-management.module').then(
                      m=> m.UserManagementModule
                    ),
                  data: {
                    title:'Manage Users',
                    description: 'Add, edit or remove users from Authentiq',
                    label: 'User Management'
                  }
              },
              {
                  path: AppRoutes.Analysis_Management,
                  canActivate: [AuthGuard],
                  loadChildren: () => 
                    import('@modules/authenticated-modules/analysis-management/analysis-management.module').then(
                      m => m.AnalysisManagementModule
                    ),
                  data: {
                    title: 'Analysis',
                    description: 'View analysis results',
                    label: 'Analysis'
                  }
              },
              {        
                  path: AppRoutes.Assessments,
                  canActivate: [AuthGuard],
                  canActivateChild: [AuthGuard],
                  loadChildren: () =>
                    import('@modules/authenticated-modules/assessment-management/assessment-management.module').then(
                      m => m.AssessmentManagementModule),
                  data: {
                    title: 'Assessment Management',
                    description: '',
                    label: ''
                  },
              },
            ]
          }
        ]
    },
    {
      path: AppRoutes.No_Auth,
      component: NotAuthComponent
    }
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthenticatedModulesRoutingModule { }
