import { AbstractControl, ValidationErrors, FormGroup } from '@angular/forms';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
  })

export class PasswordValidationService {

    static validOldPassword(control: AbstractControl) {

        return new Promise((resolve) => {
           if (control.value !== 'example') {
               resolve({invalidOldPassword: true});
           } else {
                resolve (null);
           }
           });

    }

    static requireLowerCase(control: AbstractControl): ValidationErrors | null {

        const regex = /[a-z]/g;

        if (regex.test(control.value)) {
            return null;
        } else {
            return{requireLowerCase: true };
        }
    }
    static requireNumbers(control: AbstractControl): ValidationErrors | null {

        const regex = /[0-9]/;

        if (regex.test(control.value)) {
            return null;
        } else {
            return{requireNumbers: true};
        }
    }
    static requireSymbols(control: AbstractControl): ValidationErrors | null {

        const regex = /[-!@#$%^&*()_+|~=`{}\[\]:";'<>?,.\/]/;

        if (regex.test(control.value)) {
            return null;
        } else {
            return{requireSymbols: true};
        }
    }

    static requireUpperCase(control: AbstractControl): ValidationErrors | null {

        const regex = /[A-Z]/;

        if (regex.test(control.value)) {
            return null;
        } else {
            return{requireUpperCase: true};
        }
    }

    static MustMatch(controlName: string, matchingControlName: string) {
        return (formGroup: FormGroup) => {
            const control = formGroup.controls[controlName];
            const matchingControl = formGroup.controls[matchingControlName];

            if (matchingControl.errors && !matchingControl.errors['mustMatch']) {
                return;
            }

            if (control.value !== matchingControl.value) {
                matchingControl.setErrors({ mustMatch: true });
            } else {
                matchingControl.setErrors(null);
            }
        };
    }

}
