import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { UserMenuModel } from '@models/user-menu/user-menu.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-menu-item',
    templateUrl: './menu-item.component.html',
    styleUrls: ['./menu-item.component.scss'],
    standalone: false
})
export class MenuItemComponent implements OnInit{

  @Input() item!: UserMenuModel;
  svgContent: SafeHtml | undefined;

  constructor(
    private sanitizer: DomSanitizer,
    private http: HttpClient) { }

  async ngOnInit() {
    this.http.get(this.item.icon!, { responseType: 'text' }).pipe(untilDestroyed(this))
    .subscribe(
      (svgData) => {
        this.svgContent = this.sanitizer.bypassSecurityTrustHtml(svgData);
      }
  );
  }


}



