import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppRoutes } from '@enums/app-routes.enum';
import { AuthRoutingComponent } from '@modules/non-authenticated-modules/authentication/routing/routing.component';
import { AuthGuard } from '@modules/non-authenticated-modules/authentication/services/auth.guard';

const routes: Routes = [
  {
    path:'',
    redirectTo: 'auth',
    pathMatch: 'full'
  },
  {
    path: AppRoutes.Authentication,
    component: AuthRoutingComponent,
    canMatch: [AuthGuard],
    children: [
      {
        path:'',
        loadChildren: () =>  
          import('@modules/non-authenticated-modules/authentication/authentication.module').then(
            m => m.AuthenticationModule
          ),
      }
    ]
  }
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class NonAuthenticatedModulesRoutingModule { }
