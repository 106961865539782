import { IAuthDevice } from "@aws-amplify/auth/lib-esm/types";
import { ICredentials } from "@aws-amplify/core";
import { MfaOptions } from "@enums/mfa-options.enum";
import { UserSignUp } from "@models/auth/cognito-user-signup.model";
import { CognitoUser, CognitoUserSession, ISignUpResult } from "amazon-cognito-identity-js";

export abstract class AbstractAuthService {
    abstract changePassword(user: CognitoUser, oldPassword: string, newPassword: string): Promise<string>;

    abstract completeNewPassword(user: any, password:string, attr: any): Promise<any>;

    abstract confirmSignIn(user: CognitoUser, code: string): Promise<any>;

    abstract confirmSignUp(username: string, code: string): Promise<any>;

    abstract currentAuthenticatedUser(): Promise<CognitoUser | any>;

    abstract currentCredentials(): Promise<ICredentials>;

    abstract currentSession(): Promise<CognitoUserSession>;

    abstract currentUserCredentials(): Promise<ICredentials>;

    abstract currentUserInfo(): Promise<any>;

    abstract deleteUser(): Promise<string | void>;

    abstract fetchDevices(): Promise<IAuthDevice[]>;

    abstract forgetDevice(): Promise<void>;

    abstract forgotPassword(username: string): Promise<any>;

    abstract forgotPasswordSubmit(username: string, code: string, password: string): Promise<string>;

    abstract getPreferredMFA(user: any): Promise<string>;

    abstract rememberDevice(): Promise<string | any>;

    abstract resendSignUp(username: string): Promise<any>;

    abstract setPreferredMFA(user: any, mfaMethod: MfaOptions): Promise<string>;

    abstract signUp(params: UserSignUp): Promise<ISignUpResult>

    abstract signIn(username: any, pw?: string): Promise<any>;

    abstract signOut(): Promise<any>;

    abstract updateUserAttributes(user: any, attr: any): Promise<string>;

    abstract userAttributes(user: any): Promise<any>;

    abstract userSession(user: any): Promise<any>;

    abstract verifiedContact(): Promise<any>;
}