import { Injectable } from "@angular/core";
import { AppRoutes } from "@enums/app-routes.enum";
import { UserMenuModel } from "@models/user-menu/user-menu.model";

@Injectable()
export class BuildMenuService {

    buildGeneralMenu(): UserMenuModel[]{
        return [
            {
                id: 'assessment-management-item',
                title: 'AuthentIQate',
                route: `/${AppRoutes.Assessments}`,
                icon: 'reconciliation',
                children: [
                  {
                    id: 'self-assessment',
                    title: 'Self Assess',
                    route: `/${AppRoutes.Assessments}/self-assess`,
                    icon: ''
                  }
                ],
              },
        ];
    }

    buildOperatorMenu(): UserMenuModel[]{
        return [        
            {
                id: 'assessment-management-item',
                title: 'AuthentIQate',
                route: `/${AppRoutes.Assessments}`,
                icon: 'reconciliation',
                children: [
                  {
                    id: 'perform-assessment',
                    title: 'Perform Assessment',
                    route: `/${AppRoutes.Assessments}/perform-assessments`,
                    icon: ''
                  }
                ],
              },
        ];
    }

    buildSupervisorMenu(): UserMenuModel[]{ //Manager Menu
        return [
            {
                id: 'dashboard-menu-item',
                title: 'Dashboard',
                route: `/${AppRoutes.Dashboard}`,
                icon: 'assets/svg/dashboard.svg'
            },
            {
              id: 'people-management-menu-item',
              title: 'People Management',
              route: `/${AppRoutes.Subjects}`,
              icon: 'assets/svg/people-management.svg'
            },
            {
              id: 'question-management-menu-item',
              title: 'Question Management',
              route: `/${AppRoutes.Questions}`,
              icon: 'assets/svg/question-management.svg'
            },
            {
              id: 'question-set-management-menu-item',
              title: 'Question Set Management',
              route: `/${AppRoutes.Question_Sets}`,
              icon: 'assets/svg/question-set-management.svg'
            },
            {
              id: 'user-management-menu-item',
              title: 'User Management',
              route: `/${AppRoutes.Users}`,
              icon: 'assets/svg/user-management.svg'
            },
            {
              id: 'assessment-management-item',
              title: 'AuthentIQate',
              route: `/${AppRoutes.Assessments}`,
              icon: 'reconciliation',
              children: [
                {
                  id: 'assessment-setup',
                  title: 'Set up',
                  route: `/${AppRoutes.Assessments}`,
                  icon: ''
                },
                {
                  id: 'assessments-view',
                  title: 'View Assessments',
                  route: `/${AppRoutes.Assessments}/view-assessments`,
                  icon: ''
                },
                {
                  id: 'perform-assessment',
                  title: 'Perform Assessment',
                  route: `/${AppRoutes.Assessments}/perform-assessments`,
                  icon: ''
                },
              ],
            },
        ];
    }

    buildAdminMenu(): UserMenuModel[]{
        return [
            {
                id: 'people-management-menu-item',
                title: 'People Management',
                route: `/${AppRoutes.Subjects}`,
                icon: 'assets/svg/people-management.svg'
            },
            {
                id: 'question-management-menu-item',
                title: 'Question Management',
                route: `/${AppRoutes.Questions}`,
                icon: 'assets/svg/question-management.svg'
            },
            {
                id: 'question-set-management-menu-item',
                title: 'Question Set Management',
                route: `/${AppRoutes.Question_Sets}`,
                icon: 'assets/svg/question-set-management.svg'
            },
            // {
            //     id: 'audio-management-menu-item',
            //     title: 'Audio Management',
            //     route: `/${AppRoutes.Audio}`,
            //     icon: 'assets/svg/audio-management.svg'
            // },
            {
                id: 'assessment-management-item',
                title: 'AuthentIQate',
                route: `/${AppRoutes.Assessments}`,
                icon: 'reconciliation',
                children: [
                  {
                    id: 'assessment-setup',
                    title: 'Set up',
                    route: `/${AppRoutes.Assessments}`,
                    icon: ''
                  },
                  {
                    id: 'assessments-view',
                    title: 'View Assessments',
                    route: `/${AppRoutes.Assessments}/view-assessments`,
                    icon: ''
                  },
                ],
              },
        ];
    }

    buildSuperAdminMenu(): UserMenuModel[]{
        return [
            {
                id: 'dashboard-menu-item',
                title: 'Dashboard',
                route: `/${AppRoutes.Dashboard}`,
                icon: 'assets/svg/dashboard.svg'
            },
            {
                id: 'people-management-menu-item',
                title: 'People Management',
                route: `/${AppRoutes.Subjects}`,
                icon: 'assets/svg/people-management.svg'
            },
            {
                id: 'question-management-menu-item',
                title: 'Question Management',
                route: `/${AppRoutes.Questions}`,
                icon: 'assets/svg/question-management.svg'
            },
            {
                id: 'question-set-management-menu-item',
                title: 'Question Set Management',
                route: `/${AppRoutes.Question_Sets}`,
                icon: 'assets/svg/question-set-management.svg'
            },
            // {
            //     id: 'audio-management-menu-item',
            //     title: 'Audio Management',
            //     route: `/${AppRoutes.Audio}`,
            //     icon: 'assets/svg/audio-management.svg'
            // },
            {
                id: 'user-management-menu-item',
                title: 'User Management',
                route: `/${AppRoutes.Users}`,
                icon: 'assets/svg/user-management.svg'
            },
            {
                id: 'assessment-management-item',
                title: 'AuthentIQate',
                route: `/${AppRoutes.Assessments}`,
                icon: 'reconciliation',
                children: [
                  {
                    id: 'assessment-setup',
                    title: 'Set up',
                    route: `/${AppRoutes.Assessments}`,
                    icon: ''
                  },
                  {
                    id: 'assessments-view',
                    title: 'View Assessments',
                    route: `/${AppRoutes.Assessments}/view-assessments`,
                    icon: ''
                  },
                  {
                    id: 'perform-assessment',
                    title: 'Perform Assessment',
                    route: `/${AppRoutes.Assessments}/perform-assessments`,
                    icon: ''
                  }
                ],
              },
        ];
    }
}