import { AbstractAuthService } from '@modules/non-authenticated-modules/authentication/abstract-classes/auth-service.abstract';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Observable, firstValueFrom } from 'rxjs';
import { AuthDbStoreService } from './auth.db.store';
import { LoadingService } from '@core/services/loading.service';
import { SystemRoles } from '@enums/system-roles.enum';
import { AppRoutes } from '@enums/app-routes.enum';
import { TokenService } from '@core/services/token.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  private loadingService: LoadingService;
  private isAuthenticated = false;
  private systemAccess: string;

  constructor(
    globalLoadingService: LoadingService,
    private authService: AbstractAuthService,
    private storeService: AuthDbStoreService,
    private notificationService: NzNotificationService,
    private tokenService: TokenService,
    private router: Router
  ) {
    this.loadingService = globalLoadingService;
  }

  async canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    Promise<boolean |
      UrlTree |
      Observable<boolean | UrlTree> |
      Promise<boolean | UrlTree>> {

        this.getUserAccess();

        switch(state.url){
          case `/${AppRoutes.Assessments}`:
            if(this.systemAccess === SystemRoles['Operator'] 
              || this.systemAccess === SystemRoles['General']
            ){
              return false;
            } else {
              return true;
            }
          case `/${AppRoutes.Assessments}/view-assessments`:
            if(this.systemAccess === SystemRoles['Operator'] 
              || this.systemAccess === SystemRoles['General']
            ){
              return false;
            } else {
              return true;
            }
          case `/${AppRoutes.Assessments}/perform-assessments`:
            if(this.systemAccess === SystemRoles['General']
              || this.systemAccess === SystemRoles['Admin']
            ){
              return false;
            } else {
              return true;
            }
          case `/${AppRoutes.Assessments}/self-assess`:
            if(this.systemAccess === SystemRoles['Admin']
              || this.systemAccess === SystemRoles['Supervisor']
              || this.systemAccess === SystemRoles['Operator']
              || this.systemAccess === SystemRoles['SuperAdmin']
            ){
              return false;
            } else {
              return true;
            }
          default:
            return true;
        }
  }

  async canMatch(
    route: Route,
    segments: UrlSegment[]
  ): Promise<boolean | UrlTree> {
    return segments.some(async (segment) => {
      if(segment.path === 'auth'){
        return await this.authService.currentAuthenticatedUser()
        .then(async (usr) => {
          return await this.storeService.addUser(usr, 'null')
            .then(() => {
              this.isAuthenticated = true;
              this.router.navigateByUrl('dashboard');
              return false;
            })
            .catch(() => {
              this.isAuthenticated = false;
              this.loadingService.hideLoading();
              this.notificationService.error(
                'Sign in error',
                'User storage failed. Please sign in again.'
              );
              return true;
            });
        })
        .catch((err) => {
          console.log(err)
          this.isAuthenticated = false;
          this.loadingService.hideLoading();
          return true;
        });
      } 
      else {
        await this.storeService.getAccessToken()
        .then(async (token) => {
          this.isAuthenticated = !this.tokenService.isTokenExpired(token!)
        });
        return this.isAuthenticated
      }
    });
  }

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean | UrlTree> {

    await this.getUserAccess();

    switch(route.routeConfig?.path){
      case AppRoutes.Dashboard:
        if (this.systemAccess === SystemRoles['General'] 
        || this.systemAccess === SystemRoles['Admin'] 
        || this.systemAccess === SystemRoles['Operator']){
          if(this.systemAccess === SystemRoles['Admin']){
            this.router.navigateByUrl(AppRoutes.Subjects);
            return false;
          } else if (this.systemAccess === SystemRoles['Operator']){
            this.router.navigate([AppRoutes.Assessments, 'perform-assessments']);
            return false;
          } else {
            this.router.navigate([AppRoutes.Assessments, 'self-assess']);
            return false;
          }
        }else {
          this.loadingService.hideLoading();
          return true;
        }
      case AppRoutes.Subjects:
        if(this.systemAccess === SystemRoles['Operator']
          || this.systemAccess === SystemRoles['General']
        ){
          this.router.navigate(['not-auth'], { state: { notAuth: true } });
          this.loadingService.hideLoading();
          return false;
        } else {
          return true;
        }
      case AppRoutes.Questions:
        if(this.systemAccess === SystemRoles['General']
          || this.systemAccess === SystemRoles['Operator']
        ){
          this.router.navigate(['not-auth'], { state: { notAuth: true } });
          this.loadingService.hideLoading();
          return false;
        } else {
          return true;
        }
      case AppRoutes.Question_Sets:
        if(this.systemAccess === SystemRoles['General']
        || this.systemAccess === SystemRoles['Operator']
        ){
          this.router.navigate(['not-auth'], { state: { notAuth: true } });
          this.loadingService.hideLoading();
          return false;
        } else {
          return true;
        }
      case AppRoutes.Audio:
        if(this.systemAccess === SystemRoles['General']
        || this.systemAccess === SystemRoles['Operator']
        ){
          this.router.navigate(['not-auth'], { state: { notAuth: true } });
          this.loadingService.hideLoading();
          return false;
        } else {
          return true;
        }
      case AppRoutes.Users:
        if(this.systemAccess === SystemRoles['General']
        || this.systemAccess === SystemRoles['Operator']
        ){
          this.router.navigate(['not-auth'], { state: { notAuth: true } });
          this.loadingService.hideLoading();
          return false;
        } else {
          return true;
        }
      case AppRoutes.Analysis_Management:
        if(this.systemAccess === SystemRoles['General']
        || this.systemAccess === SystemRoles['Operator']
        || this.systemAccess === SystemRoles['Admin']
        ){
          this.router.navigate(['not-auth'], { state: { notAuth: true } });
          this.loadingService.hideLoading();
          return false;
        } else {
          return true;
        }
      case AppRoutes.Assessments:
          this.loadingService.hideLoading();
          return true;
      default:
        this.router.navigate(['not-auth'], { state: { notAuth: true } });
        this.loadingService.hideLoading();
        return false;
    }
  }

  private async getUserAccess(){
    if (this.systemAccess === undefined) {
      await this.storeService.getUserAccess()
      .then((access) => {
        this.systemAccess = access[0];
        this.isAuthenticated = true;
      });
    }
  }
}
