import { AbstractAuthService } from '@modules/non-authenticated-modules/authentication/abstract-classes/auth-service.abstract';
import { Injectable } from '@angular/core';
import { IAuthDevice } from '@aws-amplify/auth/lib-esm/types';
import { ICredentials } from '@aws-amplify/core';
import { MfaOptions } from '@enums/mfa-options.enum';
import { UserSignUp } from '@models/auth/cognito-user-signup.model';
import { CognitoUser, CognitoUserSession, ISignUpResult } from 'amazon-cognito-identity-js';
import { Auth } from 'aws-amplify';

@Injectable({
  providedIn: 'root',
})
export class AuthService implements AbstractAuthService {
  constructor() {}

  changePassword(
    user: CognitoUser,
    oldPassword: string,
    newPassword: string
  ): Promise<string> {
    throw new Error('Method not implemented.');
  }

  async completeNewPassword(
    user: any,
    password: string,
    attr: any
  ): Promise<any> {
    return await Auth.completeNewPassword(user, password, attr);
  }

  async confirmSignIn(user: any, code: string): Promise<any> {
    return await Auth.confirmSignIn(user, code);
  }

  async confirmSignUp(username: string, code: string): Promise<any> {
    return await Auth.confirmSignUp(username, code);
  }

  async currentAuthenticatedUser(): Promise<CognitoUser | any> {
    return await Auth.currentAuthenticatedUser();
  }

  currentCredentials(): Promise<ICredentials> {
    throw new Error('Method not implemented.');
  }

  currentSession(): Promise<CognitoUserSession> {
    return Auth.currentSession();
  }

  currentUserCredentials(): Promise<ICredentials> {
    throw new Error('Method not implemented.');
  }

  currentUserInfo(): Promise<any> {
    throw new Error('Method not implemented.');
  }

  deleteUser(): Promise<string | void> {
    throw new Error('Method not implemented.');
  }

  fetchDevices(): Promise<IAuthDevice[]> {
    throw new Error('Method not implemented.');
  }

  forgetDevice(): Promise<void> {
    throw new Error('Method not implemented.');
  }

  async forgotPassword(username: string): Promise<any> {
    return await Auth.forgotPassword(username);
  }

  async forgotPasswordSubmit(
    username: string,
    code: string,
    password: string
  ): Promise<string> {
    return await Auth.forgotPasswordSubmit(username, code, password);
  }

  getPreferredMFA(user: any): Promise<string> {
    throw new Error('Method not implemented.');
  }

  async rememberDevice(): Promise<any> {
    return await Auth.rememberDevice();
  }

  async resendSignUp(username: string): Promise<any> {
    return await Auth.resendSignUp(username);
  }

  setPreferredMFA(user: any, mfaMethod: MfaOptions): Promise<string> {
    throw new Error('Method not implemented.');
  }

  async signUp(params: UserSignUp): Promise<ISignUpResult> {
    return await Auth.signUp(params);
  }

  async signIn(username: string, pw?: string | undefined): Promise<any> {
    return await Auth.signIn(username, pw);
  }

  async signOut(): Promise<any> {
    return await Auth.signOut();
  }

  updateUserAttributes(user: any, attr: any): Promise<string> {
    throw new Error('Method not implemented.');
  }

  userAttributes(user: any): Promise<any> {
    throw new Error('Method not implemented.');
  }

  userSession(user: any): Promise<any> {
    throw new Error('Method not implemented.');
  }

  verifiedContact(): Promise<any> {
    throw new Error('Method not implemented.');
  }
}
