import { Injectable } from '@angular/core';
import { EnvironmentConfigModel } from 'src/environments';
import { BaseService } from './base.service';
import { logger } from '@core/providers/logging';

@Injectable({providedIn: 'root' })
export class ConfigService extends BaseService {
  apiUrl!: string;
  clientId!: string;
  apiKey!: string;
  userPoolUri!: string;
  applicationLogo!: string;
  authenticationLogo!: string;
  buildNumber!: string;

  setConfig(config: EnvironmentConfigModel): void {
    this.apiUrl = this.decrypt(config.apiUrl);
    this.clientId = this.decrypt(config.clientId);
    this.apiKey = this.decrypt(config.apiKey);
    this.userPoolUri = this.decrypt(config.userPoolUri);
    this.applicationLogo = this.decrypt(config.applicationLogo);
    this.authenticationLogo = this.decrypt(config.authenticationLogo);
    this.buildNumber = config.buildNumber;
  }

  public async load(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const fail = (err: any) => {
        logger.error(err);
        window.location.href = '/app-failed.html';
      };

      await this.loadHttpConfig()
        .then((envConfig: EnvironmentConfigModel) => {
          this.setConfig(envConfig);
          resolve(true);
        })
        .catch((err) => {
          reject(fail(err));
        });
    });
  }
}
