import {BehaviorSubject, firstValueFrom, Observable} from "rxjs";
import {CognitoUser} from "amazon-cognito-identity-js";
import {ConfigService} from "@core/services/config.service";
import {Injectable} from "@angular/core";
import {NgxIndexedDBService} from "ngx-indexed-db";
import {UserData} from "@core/data/idb/models/user/data.model";

@Injectable()
export class AuthDbStoreService {

  constructor(private service: ConfigService, private dbService: NgxIndexedDBService) {
  }

  private _cognitoUser!: BehaviorSubject<CognitoUser>;
  private _cognitoUser$!: Observable<CognitoUser>;

  async addUser(usr: CognitoUser | any, pwd: string): Promise<any> {
    return await firstValueFrom(this.dbService.clear('user')).then(async () => {
      return await firstValueFrom(this.dbService.add('user', this.getUserSchema(usr, pwd))).then((store) => {
        window.localStorage.setItem('user', store.id.toString());
        this._cognitoUser = new BehaviorSubject(usr);
        this._cognitoUser$ = this._cognitoUser.asObservable();
      }).catch((err) => console.error(err));
    });
  }

  private getUserSchema(usr: any, pwd: string) {
    return {
      username: usr.username as string,
      password: this.service.encrypt(pwd) as string,
      access: usr.getSignInUserSession()?.getIdToken().payload["cognito:groups"] as string,
      userAttributes: {
        given_name: usr.getSignInUserSession()?.getIdToken().payload.given_name as string,
        initials: this.setUserInitials(usr.getSignInUserSession()?.getIdToken().payload.given_name) as string,
        gender: usr.getSignInUserSession()?.getIdToken().payload.gender as string,
        phone_number: usr.getSignInUserSession()?.getIdToken().payload.phone_number as string,
        picture: usr.getSignInUserSession()?.getIdToken().payload.picture as string,
        email: usr.getSignInUserSession()?.getIdToken().payload.email as string
      },
      session: usr.Session as string,
      accessToken: usr.getSignInUserSession()?.getAccessToken().getJwtToken() as string,
      tokenExpiration: usr.getSignInUserSession()?.getAccessToken().getExpiration(),
      idToken: usr.getSignInUserSession()?.getIdToken().getJwtToken() as string,
      refreshToken: usr.getSignInUserSession()?.getRefreshToken().getToken() as string,
    };
  }

  async addTempUser(usrname: string, pwd: string): Promise<any> {
    return await firstValueFrom(this.dbService.clear('temp_user')).then(async () => {
      return await firstValueFrom(this.dbService.add('temp_user', {
        username: usrname as string,
        password: this.service.encrypt(pwd) as string
      })).then((temp) => {
        window.localStorage.setItem('temp-user', temp.id.toString())
      }).catch((err) => console.error(err));
    });
  }

  async updateUser(user: CognitoUser) {
    await this.addUser(user, 'null')
  }

  async getUser(): Promise<UserData> {
    return await firstValueFrom(
      this.dbService.getByID('user', parseInt(window.localStorage.getItem('user')!))
    ).then((usr) => {
      const userData = usr as UserData;
      return userData;
    });
  }

  async getTempUser() {
    return await firstValueFrom(
      this.dbService.getByID('temp_user', parseInt(window.localStorage.getItem('temp-user')!))
    ).then((usr) => {
      const tempUser = usr as {
        username: string,
        password: string
      }
      return tempUser
    })
  }

  async getUserAccess() {
    return await firstValueFrom(
      this.dbService.getByID('user', parseInt(window.localStorage.getItem('user')!))
    ).then((usr) => {
      const userData = usr as UserData;
      return userData.access;
    })
  }

  getCognitoUser(): Observable<CognitoUser> {
    return this._cognitoUser$;
  }

  setCognitoUser(usr: CognitoUser) {
    this._cognitoUser = new BehaviorSubject(usr);
    this._cognitoUser$ = this._cognitoUser.asObservable();
  }

  private setUserInitials(fullname: string): string {
    var names = fullname.split(' '),
      initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }

    return initials;
  }

  decryptUserPassword(pwd: string): string {
    return this.service.decrypt(pwd);
  }

  async clearStorage() {
    this.dbService.clear('user')
      .subscribe((v) => console.log('Data cleared'));
    this.dbService.clear('temp_user')
      .subscribe((v) => console.log('Data cleared'));
    //this.dbService.deleteDatabase();
    localStorage.clear();
    sessionStorage.clear();
  }


}
