import { Component } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { LayoutPageDataService } from '@core/services/layout.service';

@Component({
  selector: 'app-authenticated-routing',
  templateUrl: './routing.component.html',
 
})
export class AuthenticatedRoutingComponent {

  layoutService: LayoutPageDataService;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute, 
    globalLayoutService: LayoutPageDataService) { 
      
    this.layoutService = globalLayoutService;

    this.router.events.subscribe((event) => this.handleRouterEvents(event));
  }

  private handleRouterEvents(event: any): void {
    switch (true) {
      case event instanceof NavigationEnd:
        this.activatedRoute.children[0].children[0].data.subscribe((v) => {
          this.layoutService.updateTitle(v['title']);
          this.layoutService.updateDescription(v['description']);
        });

        if(this.activatedRoute.children[0].children[0].children[0]){
          this.activatedRoute.children[0].children[0].children[0].data.subscribe((v) => {
            this.layoutService.updateTitle(v['title']);
            this.layoutService.updateDescription(v['description']);
          });
        }
        break;
    }
  }
  
}
