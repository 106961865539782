import { Injectable } from '@angular/core';
import { config } from 'src/environments';
import * as CryptoJS from 'crypto-js';

@Injectable({
    providedIn: 'root'
})
export class EncryptionService {
    private key = CryptoJS.enc.Utf8.parse(config.encryptKey);
    private iv = CryptoJS.enc.Utf8.parse(config.encryptIV);
    
    constructor() { }
    
    //Encryption using AES256
    encrypt(text: string): string {
        var encrypted = CryptoJS.AES.encrypt(
            CryptoJS.enc.Utf8.parse(text),
            this.key, {
            keySize: 256 / 8,
            iv: this.iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });
        return encrypted.toString();
    }

    decrypt(text: string): string {
        var decrypted = CryptoJS.AES.decrypt(
            text,
            this.key, {
            keySize: 256 / 8,
            iv: this.iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });
        return decrypted.toString(CryptoJS.enc.Utf8);
    }
}