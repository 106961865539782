import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class LayoutPageDataService {
    private pageTitleSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
    private pageDescriptionSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');

    public pageTitle$ = this.pageTitleSubject.asObservable();
    public pageDescription$ = this.pageDescriptionSubject.asObservable();

    updateTitle(pageTitle: string): void{
        this.pageTitleSubject.next(pageTitle);
    }

    updateDescription (pageDescription: string): void{
        this.pageDescriptionSubject.next(pageDescription);
    }   

    // private handleRouterEvents(event: any): void {
    //   switch (true) {
    //     case event instanceof NavigationEnd:
    //       this.activatedRoute.children[0].children[0].data.subscribe((v) => {
    //         this.updateTitle(v['title']);
    //         this.updateDescription(v['description']);
    //       });

    //       if(this.activatedRoute.children[0].children[0].children[0]){
    //         this.activatedRoute.children[0].children[0].children[0].data.subscribe((v) => {
    //           this.updateTitle(v['title']);
    //           this.updateDescription(v['description']);
    //         });
    //       }
    //       break;
    //   }
    // }

}
