import { HttpBackend, HttpClient } from "@angular/common/http";
import { Injectable, OnInit, SkipSelf } from "@angular/core";
import { config, EnvironmentConfigModel } from "@environments/index";
import { Observable, firstValueFrom } from "rxjs";
import { EncryptionService } from "./encryption.service";

@Injectable()
export class BaseService {

    constructor(
        private httpBackend: HttpBackend,
        private encService: EncryptionService) {}


    decrypt(v: string): string{
        return this.encService.decrypt(v);
    }

    encrypt(v: string): string {
        return this.encService.encrypt(v);
    }

    getSVG(uri: string): Observable<string> {
        const http = new HttpClient(this.httpBackend);
        const source$ = http.get(uri, { responseType: 'text'});

        return source$;
    }

    async loadHttpConfig(): Promise<EnvironmentConfigModel> {
        const http = new HttpClient(this.httpBackend);
        const source$ = http.get<EnvironmentConfigModel>(config.configFileLocation);

        return await firstValueFrom(source$);
    }
}