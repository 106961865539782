import { IConfig } from 'src/environments';
import { Environments } from 'src/environments/config/config.enum';

export const config: IConfig = {
    environment: Environments.dev,
    showDevErrors: true,
    configFileLocation: '../../../config.json',
    encryptKey: '04154660023005114803720021358461',
    encryptIV: '20057719206088596997581788395107',
    sentryConfig: {
        environment: 'Development',
        tracesSampleRate: 1.0,
        replaysSessionSampleRate: 1.0,
        replaysOnErrorSampleRate: 1.0
    }
};