import { LoginComponent } from './components/login/login.component';
import { NgModule } from '@angular/core';
//import { RegistrationComponent } from './components/registration/registration.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './services/auth.guard';

const routes: Routes = [
  {
    path:'',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: '',
    children: [
      {
        path: 'login',
        component: LoginComponent,
      },
      {
        canActivate: [AuthGuard],
        path: 'reset',
        component: ResetPasswordComponent,
        data: { challenge: null } 
      },
      // {
      //   path: 'registration',
      //   component: RegistrationComponent
      // }
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthenticationRoutingModule { }
