<nz-layout class="layout-wrapper">
    <nz-header>
        <div class="header">
            <a id="tenant-logo" routerLink="dashboard" [innerHTML]="tenantLogo"></a>

            <div class="avatar" nz-dropdown="nz-dropdown" [nzDropdownMenu]="menu">
                <nz-avatar [nzText]="userInitials" style="color: #f56a00; background-color: #fde3cf"></nz-avatar>

                <nz-dropdown-menu #menu="nzDropdownMenu">
                    <div>
                        <nz-card style="width:300px;">
                            <div class="profile-card-spacing">
                                <div class="user-profile-card-spacing">
                                    <nz-avatar class="avatar-dropdown" [nzShape]="'square'" [nzSize]="'large'" [nzIcon]="'user'"></nz-avatar>
                                    <div>
                                        <span class="profile-card-name">{{name}}</span>
                                        <div>{{role}}</div>
                                        <div style="font-style: italic;">{{email}}</div>
                                    </div>
                                </div>
                                <div style="margin-top: 5px;">
                                    <button nz-button="nz-button" nzType="primary" nzBlock="nzBlock" (click)="logOut()">Log out</button>
                                </div>
                                <nz-divider></nz-divider>
                                <div style="text-align: center; color: grey;">Version: 0.66.40.{{buildNumber}}</div>
                            </div>

                        </nz-card>
                    </div>
                </nz-dropdown-menu>
            </div>
        </div>
    </nz-header>
    <nz-layout>
        <nz-sider nzWidth="255px" nzTheme="light" [nzBreakpoint]="'xl'" [nzCollapsible]="true">
            <ul nz-menu="nz-menu" nzMode="inline" class="side-menu">
                <div *ngFor="let item of menuItems">
                    <li nz-menu-item="nz-menu-item" *ngIf="!item.children">
                        <app-menu-item [item]="item"></app-menu-item>
                    </li>
                    <li
                        nz-submenu="nz-submenu" *ngIf="item.children" [nzTitle]="item.title" [nzIcon]="item.icon!">
                        <!-- <app-menu-item [item]="item"></app-menu-item> -->
                        <ul>
                            <li nz-menu-item="nz-menu-item" *ngFor="let subItem of item.children" nzTitle="item.title">
                                <app-menu-item [item]="subItem"></app-menu-item>
                            </li>
                        </ul>
                    </li>
                </div>
            </ul>
        </nz-sider>
        <nz-layout class="inner-layout">
            <nz-page-header class="site-page-header" [nzTitle]="pageTitle" [nzSubtitle]="pageDescription" *ngIf="this.role !== 'General'">
                <nz-breadcrumb nz-page-header-breadcrumb="nz-page-header-breadcrumb" [nzAutoGenerate]="true" [nzRouteLabel]="'label'">
                    <nz-breadcrumb-item>
                        <a [routerLink]="['dashboard']" id="home">Home</a>
                    </nz-breadcrumb-item>
                </nz-breadcrumb>
            </nz-page-header>
            <nz-content class="page-content-wrapper">
                <router-outlet></router-outlet>
            </nz-content>
        </nz-layout>
    </nz-layout>
</nz-layout>

