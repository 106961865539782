import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { DevErrorHandler } from 'dev-error-reporter';
import { config } from 'src/environments';


@Injectable()
export class GlobalErrorHandler extends ErrorHandler {
  // Creates new instance, the instance ensures errors are not shown twice until dismisses
  private errorReporter = new DevErrorHandler({
    appendToElement: 'app-root', // <-- App root as defined in index.html
  });

  constructor(private injector: Injector) {
    super();
  }

  override handleError(error: any): void {
    if (config.showDevErrors) {
      // <-- Managed in your own code, should never show in prod
      this.errorReporter.showError(error);
    } else {
      const chunkFailedMessage = /Loading chunk [\d]+ failed/;

      if (chunkFailedMessage.test(error.message)) {
        window.location.reload();
      }
    }

    // Keep default behavior
    super.handleError(error);
  }
}
