import { AmplifyAuthenticatorModule } from "@aws-amplify/ui-angular";
import { AuthenticatedModulesRoutingModule } from "./authenticated-modules-routing.module";
import { AuthenticatedRoutingComponent } from "./module-routing/routing.component";
import { BuildMenuService } from "@core/services/build-menu.service";
import { LayoutComponent } from "@core/components/layout/layout.component";
import { LayoutPageDataService } from "@core/services/layout.service";
import { MenuItemComponent } from "@core/components/layout/menu-item/menu-item.component";
import { NgModule } from "@angular/core";
import { NgxIndexedDBModule } from "ngx-indexed-db";
import { NotAuthComponent } from "@core/components/not-auth/not-auth.component";
import { SharedModule } from "@shared/shared.module";

@NgModule({
  imports: [
    AmplifyAuthenticatorModule,
    AuthenticatedModulesRoutingModule,
    NgxIndexedDBModule,
    SharedModule,
  ],
  declarations: [
    AuthenticatedRoutingComponent,
    LayoutComponent,
    MenuItemComponent,
    NotAuthComponent
  ],
  providers: [
    LayoutPageDataService,
    BuildMenuService,
  ],
  exports: [
    AuthenticatedRoutingComponent
  ]
})
export class AuthenticatedModulesModule { }
