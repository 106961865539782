export enum AppRoutes {
    Authentication = 'auth',
    Dashboard = 'dashboard',
    Subjects = 'people-management',
    Questions = 'question-management',
    Question_Sets = 'question-set-management',
    Audio = 'audio-management',
    Users = 'user-management',
    Analysis_Results = 'results',
    Analysis_Details = 'details/:id',
    Analysis_Management = 'analysis',
    Assessments = 'authentiq',
    No_Auth = 'not-auth'
}