import { APP_INITIALIZER, ErrorHandler, NgModule } from "@angular/core";
import { AppComponent } from "./app.component";
import { AuthenticatedModulesModule } from "@modules/authenticated-modules/authenticated-modules.module";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from "@angular/platform-browser";
import { ConfigService } from "@core/services/config.service";
import { dbConfig } from "@core/data/idb/config/idb.config";
import { en_US, NZ_I18N } from "ng-zorro-antd/i18n";
import { EnvironmentLoader } from "@core/app-init/environment-loader";
import { Environments, config } from "@environments/index";
import { GlobalErrorHandler } from "@core/providers/global-error-handler";
import { HttpClientModule } from "@angular/common/http";
import { LoadingComponent } from "@core/components/loading/loading.component";
import { LoadingService } from "@core/services/loading.service";
import { NgxIndexedDBModule, NgxIndexedDBService } from "ngx-indexed-db";
import { NonAuthenticatedModulesModule } from "@modules/non-authenticated-modules/non-authenticated-modules.module";
import { NzMessageModule } from "ng-zorro-antd/message";
import { NzModalModule } from "ng-zorro-antd/modal";
import { NzSpinModule } from "ng-zorro-antd/spin";
import { RouterModule } from "@angular/router";
import { ServiceWorkerModule } from "@angular/service-worker";
import { TenantConfigService } from "@core/services/tenant-config.service";

@NgModule({
  imports: [
    NgxIndexedDBModule.forRoot(dbConfig),
    AuthenticatedModulesModule,
    BrowserAnimationsModule,
    BrowserModule,
    HttpClientModule,
    NonAuthenticatedModulesModule,
    NzMessageModule,
    NzModalModule,
    NzSpinModule,
    RouterModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: config.environment === Environments.prod || config.environment === Environments.uat ? true : false,
    }),
  ],
  declarations: [
    AppComponent,
    LoadingComponent,
  ],

  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: EnvironmentLoader,
      deps: [ ConfigService ],
      multi: true,
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    { 
      provide: NZ_I18N, 
      useValue: en_US 
    },
    { 
      provide: TenantConfigService,
      deps: [ConfigService]
    },
   //LoadingService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
