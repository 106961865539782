import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { LoadingService } from '@core/services/loading.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Subscription, debounceTime } from 'rxjs';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements AfterViewInit {

  debounceTime = 200;
  loading = false;
  loadingSubscription: Subscription;
  loadingService: LoadingService;

  constructor(
    globalLoadingService: LoadingService,
    private elmRef: ElementRef,
    private changeDetectorRef: ChangeDetectorRef
  ) { 
    this.loadingService = globalLoadingService;
  }

  ngAfterViewInit(): void {
    this.elmRef.nativeElement.style.display = 'none';
    this.loadingSubscription = this.loadingService.loading$
      .pipe(debounceTime(this.debounceTime))
      .subscribe((status: boolean) => {
        this.elmRef.nativeElement.style.display = status ? 'initial' : 'none';
        this.changeDetectorRef.detectChanges();
      });
  }

}
