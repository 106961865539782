import { DBConfig } from "ngx-indexed-db";

// Ahead of time compiles requires an exported function for factories
export function migrationFactory(){
    return {};
}

export const dbConfig: DBConfig = {
    name: 'Authentiq-Db',
    version: 1,
    objectStoresMeta: [
        {
            store: 'user',
            storeConfig: { keyPath: 'id', autoIncrement: true },
            storeSchema: [
                { name: 'username', keypath: 'username', options: { unique: true} },
                { name: 'password', keypath: 'password', options: { unique: false} },
                { name: 'access', keypath: 'access', options: { unique: false} },
                { name: 'userAttributes', keypath: 'userAttributes', options: { unique: false} },
                { name: 'session', keypath: 'session', options: { unique: true} },
                { name: 'accessToken', keypath: 'accessToken', options: { unique: true} },
                { name: 'tokenExpiration', keypath: 'tokenExpiration', options: { unique: false} },
                { name: 'idToken', keypath: 'idToken', options: { unique: true} },
                { name: 'refreshToken', keypath: 'refreshToken', options: { unique: true} },
            ]
    },
    {
        store: 'temp_user',
        storeConfig: { keyPath: 'id', autoIncrement: true},
        storeSchema: [
            { name: 'username', keypath: 'username', options: { unique: true}},
            { name: 'password', keypath: 'password', options: { unique: false}}
        ]
    }
    ],
    // provide the migration factory to the DBConfig
    migrationFactory
}
