import { Amplify } from 'aws-amplify';
import { AppModule } from './app/app.module';
import { config, Environments } from './environments';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { registerLocaleData } from '@angular/common';
import awsmobile from './aws-exports';
import en from '@angular/common/locales/en';

registerLocaleData(en);

Amplify.configure(awsmobile);

if (config.environment === Environments.prod || config.environment === Environments.uat) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

  // ServiceWorkerModule.register('ngsw-worker.js', {
  //   enabled: config.environment === Environments.prod || config.environment === Environments.uat,
  //   // Register the ServiceWorker as soon as the application is stable
  //   // or after 30 seconds (whichever comes first).
  //   registrationStrategy: 'registerWhenStable:30000'
  // });
