import { AbstractAuthService } from "@modules/non-authenticated-modules/authentication/abstract-classes/auth-service.abstract";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { SignUpParams } from "@aws-amplify/auth";
import { AuthError } from "@aws-amplify/auth/lib-esm/Errors";
import { AuthOptions, ConfirmSignUpOptions, ClientMetaData, UsernamePasswordOpts, GetPreferredMFAOpts, CurrentUserOpts, SignOutOpts, FederatedSignInOptions, FederatedResponse, FederatedUser, FederatedSignInOptionsCustom, IAuthDevice } from "@aws-amplify/auth/lib-esm/types";
import { CredentialsClass, ICredentials } from "@aws-amplify/core";
import { MfaOptions } from "@enums/mfa-options.enum";
import { UserSignUp } from "@models/auth/cognito-user-signup.model";
import { NodeCallback, ISignUpResult, MFAOption, CognitoUser, CognitoUserSession, CognitoUserAttribute } from "amazon-cognito-identity-js";
import { Observable } from "rxjs";


@Injectable({
    providedIn: 'root'
})
export class MockAuthService implements AbstractAuthService {
    constructor(private http: HttpClient) { }
    
    signUp(params: UserSignUp): Promise<ISignUpResult> {
        throw new Error("Method not implemented.");
    }
    
    changePassword(user: CognitoUser, oldPassword: string, newPassword: string): Promise<string> {
        throw new Error("Method not implemented.");
    }
    completeNewPassword(user: any, password: string): Promise<any> {
        throw new Error("Method not implemented.");
    }
    confirmSignIn(user: any, code: string): Promise<any> {
        throw new Error("Method not implemented.");
    }
    confirmSignUp(username: string, code: string): Promise<any> {
        throw new Error("Method not implemented.");
    }
    currentAuthenticatedUser(): Promise<any> {
        throw new Error("Method not implemented.");
    }
    currentCredentials(): Promise<ICredentials> {
        throw new Error("Method not implemented.");
    }
    currentSession(): Promise<any> {
        throw new Error("Method not implemented.");
    }
    currentUserCredentials(): Promise<ICredentials> {
        throw new Error("Method not implemented.");
    }
    currentUserInfo(): Promise<any> {
        throw new Error("Method not implemented.");
    }
    deleteUser(): Promise<string | void> {
        throw new Error("Method not implemented.");
    }
    fetchDevices(): Promise<IAuthDevice[]> {
        throw new Error("Method not implemented.");
    }
    forgetDevice(): Promise<void> {
        throw new Error("Method not implemented.");
    }
    forgotPassword(username: string): Promise<any> {
        throw new Error("Method not implemented.");
    }
    forgotPasswordSubmit(username: string, code: string, password: string): Promise<string> {
        throw new Error("Method not implemented.");
    }
    getPreferredMFA(user: any): Promise<string> {
        throw new Error("Method not implemented.");
    }
    rememberDevice(): Promise<any> {
        throw new Error("Method not implemented.");
    }
    resendSignUp(username: string): Promise<any> {
        throw new Error("Method not implemented.");
    }
    setPreferredMFA(user: any, mfaMethod: MfaOptions): Promise<string> {
        throw new Error("Method not implemented.");
    }
    signIn(usernameOrSignInOpts: any, pw?: string | undefined): Promise<any> {
        throw new Error("Method not implemented.");
    }
    signOut(): Promise<any> {
        throw new Error("Method not implemented.");
    }
    updateUserAttributes(user: any, attr: any): Promise<string> {
        throw new Error("Method not implemented.");
    }
    userAttributes(user: any): Promise<any> {
        throw new Error("Method not implemented.");
    }
    userSession(user: any): Promise<any> {
        throw new Error("Method not implemented.");
    }
    verifiedContact(): Promise<any> {
        throw new Error("Method not implemented.");
    }
}