import { BehaviorSubject, Observable, firstValueFrom } from "rxjs";
import { CognitoUser } from "amazon-cognito-identity-js";
import { ConfigService } from "@core/services/config.service";
import { Injectable } from "@angular/core";
import { NgxIndexedDBService } from "ngx-indexed-db";
import { UserData } from "@core/data/idb/models/user/data.model";

@Injectable()
export class AuthDbStoreService {

    constructor(private service: ConfigService, private dbService: NgxIndexedDBService){}

    private _cognitoUser!: BehaviorSubject<CognitoUser>;
    private _cognitoUser$!: Observable<CognitoUser>;

    async addUser(usr: CognitoUser | any , pwd: string): Promise<any> {
        return await firstValueFrom(this.dbService.clear('user')).then(async (res) => {
            if(res){
                return await firstValueFrom(this.dbService.add('user', {
                    username: usr.username as string,
                    password: this.service.encrypt(pwd) as string,
                    access: usr.getSignInUserSession()?.getIdToken().payload["cognito:groups"] as string,
                    userAttributes: {
                        given_name: usr.getSignInUserSession()?.getIdToken().payload.given_name as string,
                        initials: this.setUserInitials(usr.getSignInUserSession()?.getIdToken().payload.given_name) as string,
                        gender: usr.getSignInUserSession()?.getIdToken().payload.gender as string,
                        phone_number: usr.getSignInUserSession()?.getIdToken().payload.phone_number as string,
                        picture: usr.getSignInUserSession()?.getIdToken().payload.picture as string,
                        email: usr.getSignInUserSession()?.getIdToken().payload.email as string
                    },
                    session: usr.Session as string,
                    accessToken: usr.getSignInUserSession()?.getAccessToken().getJwtToken() as string,
                    idToken: usr.getSignInUserSession()?.getIdToken().getJwtToken() as string,
                    refreshToken: usr.getSignInUserSession()?.getRefreshToken().getToken() as string,
                })).then((store) => {
                    window.localStorage.setItem('user', store.id.toString());
                    this._cognitoUser = new BehaviorSubject(usr);
                    this._cognitoUser$ = this._cognitoUser.asObservable();
                }).catch((err) => console.error(err));
            }
        });
    }

    async addTempUser(usrname: string , pwd: string): Promise<any>{
        return await firstValueFrom(this.dbService.clear('temp_user')).then(async (res) => {
            if(res){
                return await firstValueFrom(this.dbService.add('temp_user', {
                    username: usrname as string,
                    password: this.service.encrypt(pwd) as string
                })).then((temp) => {
                    window.localStorage.setItem('temp-user', temp.id.toString())
                }).catch((err) => console.error(err));
            }
        });
    }

    async updateUser(){
        return await firstValueFrom(this.getCognitoUser())
        .then(async (usr) => {
            return await this.addUser(usr, 'null')
            .then((v) => {})
            .catch((err) => console.error(err));
        });
    }

    async getUser(): Promise<UserData>{
        return await firstValueFrom(
            this.dbService.getByID('user', parseInt(window.localStorage.getItem('user')!))
        ).then((usr) => {
            const userData = usr as UserData;
            return userData;
        });
    }

    async getTempUser(){
        return await firstValueFrom(
            this.dbService.getByID('temp_user', parseInt(window.localStorage.getItem('temp-user')!))
        ).then((usr) => { 
            const tempUser = usr as {
                username: string,
                password: string
            }
            return tempUser 
        })
    }

    async getUserAccess(){
        return await firstValueFrom(
            this.dbService.getByID('user', parseInt(window.localStorage.getItem('user')!))
        ).then((usr) => {
            const userData = usr as UserData;
            return userData.access;
        })
    }

    async getAccessToken(){
        //expired test token
        //return 'eyJraWQiOiJjQ1M4ZE9pQ2x3ZitaMWVTUTcrb3VIZXpvbmdSTTFqam85bUNXbzBLR2RRPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiI5MmQ1MjQyNC0xMDgxLTcwNTgtZWI3My01OTBkNjkxODY2ZjMiLCJkZXZpY2Vfa2V5IjoiZXUtd2VzdC0xXzQzZjE2NjkxLTQwZjUtNDE5My04NGZkLWQ4NzFhYjA5ZDIzOSIsImNvZ25pdG86Z3JvdXBzIjpbImx2YVN1cGVyQWRtaW5Vc2VycyJdLCJpc3MiOiJodHRwczpcL1wvY29nbml0by1pZHAuZXUtd2VzdC0xLmFtYXpvbmF3cy5jb21cL2V1LXdlc3QtMV9lTzJYOTJkd0kiLCJjbGllbnRfaWQiOiI3N3FrdmFqcGhsN282cmR1dGxva2J2a24yNCIsIm9yaWdpbl9qdGkiOiJmMzM0ZDE4OS0wYzNiLTRlYTAtODVhMS1kNzhkYWFkMGFjNWQiLCJldmVudF9pZCI6ImYzYTFiZWJiLThjMzMtNDVlMy05MTVjLThjNzcyYzRjMjg4YyIsInRva2VuX3VzZSI6ImFjY2VzcyIsInNjb3BlIjoiYXdzLmNvZ25pdG8uc2lnbmluLnVzZXIuYWRtaW4iLCJhdXRoX3RpbWUiOjE3MTU2MDkzMDksImV4cCI6MTcxNTYxMjkwOSwiaWF0IjoxNzE1NjA5MzA5LCJqdGkiOiI5ZjQ0YTZlNy00ZTkyLTQ1NTItOTQ2NC03ZDQzOWNiMWY5OWQiLCJ1c2VybmFtZSI6IjkyZDUyNDI0LTEwODEtNzA1OC1lYjczLTU5MGQ2OTE4NjZmMyJ9.v4be0RCEVrlb7PcVUB8F0FLYmXAX_OkqScwrk8X6Fm7H9NfV7JFzs69JQMhZafx6ZWkkPuHnw2-juWLD1_r3uPrKh-hAQAUqAiTF0QVufvTRlgLRtRXlpoW3WTIPWv9y8sEkbE_fgUhogG6GXsEdF8jy9UNKOWMyDiDzVF4BKz-vBa1v_JXz4H4Gc2fX-FK_rFYnmPHiclikoSjywxH3QaYfhUFUVC-jkiarf4NQFZ17KZNmGFkeiVTkdGfZOjU-DQdG1dENj64rueNcJD5M42ANs1LrN2wdCx1MwOCc3gMs8iKl8mlTnYJARt87MQUCf0yZED6zpSq5uSWS5DsDWQ'
        return await firstValueFrom(
            this.dbService.getByID('user', parseInt(window.localStorage.getItem('user')!))
        ).then((usr) => {
            const userData = usr as UserData;
            return userData.accessToken;
        });
    }

    async getIdToken(){
        return await firstValueFrom(
            this.dbService.getByID('user', parseInt(window.localStorage.getItem('user')!))
        ).then((usr) => {
            const userData = usr as UserData;
            return userData.idToken;
        });
    }

    getCognitoUser(): Observable<CognitoUser>{
        return this._cognitoUser$;
    }

    setCognitoUser(usr: CognitoUser){
        this._cognitoUser = new BehaviorSubject(usr);
        this._cognitoUser$ = this._cognitoUser.asObservable();
    }

    private setUserInitials(fullname: string): string {
        var names = fullname.split(' '),
        initials = names[0].substring(0, 1).toUpperCase();

        if (names.length > 1) {
            initials += names[names.length - 1].substring(0, 1).toUpperCase();
        }

        return initials;
    }

    decryptUserPassword(pwd: string): string {
        return this.service.decrypt(pwd);
    }

    async clearStorage(){
        this.dbService.clear('user')
        .subscribe((v) => console.log('Data cleared'));
        this.dbService.clear('temp_user')
        .subscribe((v) => console.log('Data cleared'));
        //this.dbService.deleteDatabase();
        localStorage.clear();
        sessionStorage.clear();
    }


}