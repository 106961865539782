import { AbstractAuthService } from '@modules/non-authenticated-modules/authentication/abstract-classes/auth-service.abstract';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { PasswordValidationService } from '@shared/services/password-validation.service';
import { CognitoUser, CognitoUserAttribute } from 'amazon-cognito-identity-js';
import { AuthDbStoreService } from '@modules/non-authenticated-modules/authentication/services/auth.db.store';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CognitoUserAttributes } from '@models/auth/cognito-user-attr.model';
import { TenantConfigService } from '@core/services/tenant-config.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

var data: CognitoUserAttributes = {
  gender : 'null',
  given_name : 'null',
  picture: 'null'
};

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  resetPasswordForm!: UntypedFormGroup;
  user!: CognitoUser;
  tenantAuthLogo: SafeHtml | undefined;

  isPasswordChange = false;
  oldPasswordVisible = false;
  newPasswordVisible = false;
  verifyPasswordVisible = false;
  newUser = false;
  challenge: string | undefined;
  username: string | undefined;
  newUsername: string | undefined;
  otp: string | undefined;
  btnSubmitText = 'Reset Password';
  tempPwd: string;
  submit = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AbstractAuthService,
    private authStore: AuthDbStoreService,
    private fb: UntypedFormBuilder, 
    private notificationService: NzNotificationService,
    private router: Router,
    private sanitizer: DomSanitizer,
    private tenantService: TenantConfigService
  ) { 
    this.activatedRoute.queryParams
    .pipe(untilDestroyed(this))
    .subscribe(params => {
      this.username = params['username'] || undefined;
      this.otp = params['mfa'] || undefined;
      this.newUser = params['user'] || undefined;
    });

    this.challenge = this.router.getCurrentNavigation()?.extras!.state!['challenge'];
    this.newUsername= this.router.getCurrentNavigation()?.extras!.state!['username'];
    data.given_name = this.router.getCurrentNavigation()?.extras!.state!['name'];
    data.phone_number = this.router.getCurrentNavigation()?.extras!.state!['number'];
  }

  async ngOnInit() {
    this.initForm();

    await this.setTenantLogo();
    
    if(this.challenge === 'NEW_PASSWORD_REQUIRED'){
      this.oldPassword?.disable();
      await this.retrieveTempUser();
    }

    if(this.username && this.otp){
      this.oldPassword?.disable();
    }

    if(this.newUser){
      this.btnSubmitText = 'Set Password';
      this.oldPassword?.disable();
    }

    this.isPasswordChange = !(this.oldPassword?.disabled!);
  }

  private async setTenantLogo(){
    this.tenantAuthLogo = this.sanitizer.bypassSecurityTrustHtml(await this.tenantService.getAuthLogo());
  }

  private initForm(): void {
    this.resetPasswordForm = this.fb.group({
      oldPassword: ['', [Validators.required]],
      newPassword: ['', [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(99),
        PasswordValidationService.requireLowerCase,
        PasswordValidationService.requireNumbers,
        PasswordValidationService.requireSymbols,
        PasswordValidationService.requireUpperCase
      ]],
      verifyPassword: ['', [Validators.required]]
    },
    { validator: PasswordValidationService.MustMatch('newPassword', 'verifyPassword')},
    );
  }

  resetPassword(){
    this.submit = true;
    if(this.challenge === 'NEW_PASSWORD_REQUIRED'){
      this.authService.completeNewPassword(this.user, this.verifyPassword?.value, data)
      .then(async (u) => {
        this.submit = false;
        //console.log(u);
        if(u.challengeName === 'SMS_MFA'){
          this.authStore.setCognitoUser(u);
          this.router.navigate(['auth', 'login'], {queryParams: { reset: true}});
        }

        if(u.challengeName === undefined){
          //this.authStore.setCognitoUser(u);
          await this.authStore.addUser(u, this.verifyPassword?.value).then(() => this.router.navigateByUrl('dashboard'));
        }
        //console.log(u)
        // await this.authStore.addUser(this.user, this.verifyPassword?.value)
        // .then(() => this.router.navigate(['auth','login'], {queryParams: { reset: true}}))
        // .catch((e) => this.notificationService.error('Internal error', 'Failed to store user')) 
      })
      .catch((e) => {
        this.submit = false;
        // console.log(e)
        this.notificationService.error('Auth error', e.message)
      }) 
      }
    
    if(this.username && this.otp){
      this.authService.forgotPasswordSubmit(this.username, this.otp, this.verifyPassword?.value)
      .then((v) => { 
        this.submit = false;
        this.notificationService.info('Sign in info', v); 
        this.router.navigateByUrl('auth/login');
      })
      .catch((e) => {
        this.notificationService.error('Auth error', e.message);
        this.submit = false;
        this.router.navigate(['auth','login']);
    });
    }

    if(this.newUser){
      this.authService.signUp({
        username: this.newUsername!,
        password: this.verifyPassword?.value,
        attributes: data
      })
      .then(async (v) => {
        this.notificationService.info('Sign up info', `Verification code sent to ${v.codeDeliveryDetails.Destination}`);
        this.submit = false;
        this.router.navigate(['auth','login'], {queryParams: { signup: true, newUsername: this.newUsername }});
      })
      .catch((e) => {
        console.log(e);
        this.router.navigate(['auth','login']);
        this.submit = false;
      });
    }
  }

  private async retrieveTempUser(){
    await this.authStore.getTempUser().then(async (v) => {
      this.username = v?.username;
      this.tempPwd = v?.password!;
    }).catch((e) => console.log(e));

    await this.authService.signIn(this.username, this.authStore.decryptUserPassword(this.tempPwd))
    .then((u) => {
      //console.log(u)
      this.user = u;
    }).catch((e) => console.log(e));
  }

  get oldPassword() {
    return this.resetPasswordForm.get('oldPassword');
  }

  get newPassword() {
    return this.resetPasswordForm.get('newPassword');
  }

  get verifyPassword() {
    return this.resetPasswordForm.get('verifyPassword');
  }
}
