import { Component } from '@angular/core';

@Component({
  selector: 'app-auth-routing',
  templateUrl: './routing.component.html',
 
})
export class AuthRoutingComponent {

  constructor() { }
  
}
