import { Amplify } from 'aws-amplify';
import { AppModule } from './app/app.module';
import { config, Environments } from './environments';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { registerLocaleData } from '@angular/common';
import * as Sentry from "@sentry/angular";
import awsmobile from './aws-exports';
import en from '@angular/common/locales/en';

Sentry.init({
  dsn: "https://ee25814d43a63095f1b39b790bca72ce@o4508335976153088.ingest.de.sentry.io/4508336814162000",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  environment: config.sentryConfig.environment,
  // Tracing
  tracesSampleRate: config.sentryConfig.tracesSampleRate, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", "https://authentiq-dev-api.xtnd-dap.digital", "https://authentiq-uat-api.xtnd-dap.digital", "https://authentiq-api.xtnd-dap.digital"],
  // Session Replay
  replaysSessionSampleRate: config.sentryConfig.replaysSessionSampleRate, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: config.sentryConfig.replaysOnErrorSampleRate, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

registerLocaleData(en);

Amplify.configure(awsmobile);

if (config.environment === Environments.prod 
  || config.environment === Environments.uat
  || config.environment === Environments.sandbox) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

  // ServiceWorkerModule.register('ngsw-worker.js', {
  //   enabled: config.environment === Environments.prod || config.environment === Environments.uat,
  //   // Register the ServiceWorker as soon as the application is stable
  //   // or after 30 seconds (whichever comes first).
  //   registrationStrategy: 'registerWhenStable:30000'
  // });
