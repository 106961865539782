//import { db } from '@core/services/db.service';
import { AuthDbStoreService } from "@modules/non-authenticated-modules/authentication/services/auth.db.store";
import { ConfigService } from "@core/services/config.service";
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, from, lastValueFrom } from "rxjs";
import { TokenService } from "@core/services/token.service";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

    // list of url's to exclude from headers
    excludeURLList = [
    '/addUserToGroup', 
    '/removeUserFromGroup', 
    '/createUser',
    'deleteUser', 
    '/confirmUserSignUp', 
    '/disableUser',
    '/enableUser', 
    '/getUser', 
    '/listUsers', 
    '/listGroups', 
    '/listGroupsForUser', 
    '/listUsersInGroup', 
    '/signUserOut',
    '/reset',
    '/resend'
    ];

    constructor(configService: ConfigService,
        private storeService: AuthDbStoreService,
        private tokenService: TokenService
    ){
        this.key = configService.apiKey;
    }

    accessToken!: string;
    idToken!: string;
    key!: string;
    
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // convert promise to observable using 'from' operator
        return from(this.handle(req, next))
    }

    async handle(req: HttpRequest<any>, next: HttpHandler){
        let excludeFound = this.excludeURLList.filter(uri => {
            return req.url.includes(uri)
        });

        await this.acquireTokens();

        //Check if token is expired
        if(this.tokenService.isTokenExpired(this.accessToken)){
           await this.tokenService.refreshToken().then(async(v) => {
            await this.acquireTokens();
           });
        } 

        if(this.accessToken && this.key && excludeFound.length <= 0){
            req = req.clone({
                headers: req.headers
                .set('Authorization', `Bearer ${this.accessToken}`)
                .set('Authentication', `Token ${this.idToken}`)
                .set('X-Api-Key', this.key)
            });
        } else if(this.accessToken && this.key && excludeFound.length > 0){
            req = req.clone({
                headers: req.headers
                .set('Authentication', `${this.idToken}`)
                .set('X-Api-Key', this.key)
            });
        }

        return await lastValueFrom(next.handle(req));
    }

    private async acquireTokens(){
        await this.storeService.getAccessToken()
        .then((token) => {
            this.accessToken = token!;
        });

        await this.storeService.getIdToken()
        .then((token) => {
            this.idToken = token!;
        });
    }

}