import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LoadingService } from '@core/services/loading.service';

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
  private activeRequests = 0;
  private loadingService: LoadingService;

  /**
   * URLs for which the loading screen should not be enabled
   */
  skippUrls = [''];

  constructor(globalLoadingService: LoadingService) {
    this.loadingService = globalLoadingService;
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let displayLoadingScreen = true;

    for (const skippUrl of this.skippUrls) {
      if (new RegExp(skippUrl).test(req.url)) {
        displayLoadingScreen = false;
        break;
      }
    }

    // Display loading animation on the UI
    if (displayLoadingScreen) {
      if (this.activeRequests === 0) {
        this.loadingService.showLoading();
      }

      // Increment the active requests count
      this.activeRequests++;

      // Pass the request to the next handler
      return next.handle(req).pipe(
        finalize(() => {
          // Decrement the active requests count
          this.activeRequests--;

          if (this.activeRequests === 0) {
            // Hide loading animation on the UI when there are no active requests
            this.loadingService.hideLoading();
          }
        })
      );
    } else {
      return next.handle(req);
    }
  }
}
