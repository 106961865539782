import { AbstractAuthService } from "@modules/non-authenticated-modules/authentication/abstract-classes/auth-service.abstract";
import { AuthDbStoreService } from "@modules/non-authenticated-modules/authentication/services/auth.db.store";
import { CognitoUser } from "amazon-cognito-identity-js";
import { Injectable } from "@angular/core";
import { JwtPayload, jwtDecode } from "jwt-decode";
import { LoadingService } from "./loading.service";
import { Router } from "@angular/router";

@Injectable({
    providedIn: 'root'
})
export class TokenService {

    refreshTokenInProgress = false;
    loadingService: LoadingService;
    //private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);


    constructor(
        globalLoadingService: LoadingService,
        private authService: AbstractAuthService, 
        private dbStoreService: AuthDbStoreService,
        private router: Router, 
    ){
        this.loadingService = globalLoadingService;
    }

   /**
   * Refresh the JWT token.
   * @returns A promise that resolves with the new tokens or rejects with an error
   */
    async refreshToken(){
        if(!this.refreshTokenInProgress){

            this.refreshTokenInProgress = true;

            try {
                const currentUser = await this.authService.currentAuthenticatedUser() as CognitoUser;
                const currentSession = await this.authService.currentSession();

                if(currentSession.isValid()){
                    this.dbStoreService.setCognitoUser(currentUser);
                    this.dbStoreService.updateUser().then(() => this.refreshTokenInProgress = false);
                }else{
                    this.refreshTokenInProgress = false;
                    this.logOut();
                }


            } catch(err){
                console.error(err);
                this.refreshTokenInProgress = false;
                this.logOut();
            }
     }
    }   

    private async logOut(){
        this.loadingService.showLoading();
        await this.authService.signOut()
        .then(async () => {
            await this.dbStoreService.clearStorage()
            .then(() => {
                this.router.navigateByUrl('auth/login');
                this.loadingService.hideLoading();
            });
        });
    }

   /**
   * Get the expiration date of the JWT token.
   * @param token - JWT token
   * @returns - Expiration date or null if expiration date is not found
   */
   private getTokenExpirationDate(token: string): Date | null {
        try {
            const decodedToken = jwtDecode<JwtPayload>(token);
            const date = new Date(0);

            if(decodedToken.exp === undefined){
                return null;
            } else{
                date.setUTCSeconds(decodedToken.exp);
                return date;
            }
        } catch(err) {
            console.error(err);
            return null;
        }
    }

   /**
   * Check if the JWT token is expired.
   * @param token - JWT token
   * @returns - true if the token is expired, false otherwise
   */
    isTokenExpired(token: string): boolean {
        const expirationDate = this.getTokenExpirationDate(token);

        if(!expirationDate){
            return true;
        }

        return expirationDate.valueOf() < new Date().valueOf();
    }

}