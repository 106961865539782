import { Component } from "@angular/core";

@Component({
    selector: 'app-not-auth',
    templateUrl: './not-auth.component.html',
    styleUrls: ['./not-auth.component.scss'],
    standalone: false
})
export class NotAuthComponent {
    constructor(){}
}